import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, UncontrolledDropdown } from 'reactstrap';
import reactCSS from 'reactcss';
import { COLORS } from '../../utils/color';
import './style.css'
import { Account_Settings_Modal } from '../CommonModals';
import {useTranslation} from 'react-i18next'
import Icons from '../../shared_components/ui_component_library/Icons';
import { IDropdownItemRS, IDropdownMenuRS, IDropdownToggleRS } from '../IDropdownRS';

const InfurniaMainNavbar = ({ store_details, org_key, logout, set_page_loader }) => {
    const [show_account_settings, set_show_account_settings] = useState(false);
    const [is_help_section_dropdown_open, set_is_help_section_dropdown_open] = useState(false);
    
    const [admin_link, set_admin_link] = useState('admin.infurnia.com');
	const [mes_link, set_mes_link] = useState('https://mes.infurnia.com');
    const {t} = useTranslation()
    
    const close_account_settings = () => {
        set_show_account_settings(false)
    }
    const open_account_settings = () => {
        set_show_account_settings(true)
    }

    const toggle_fullscreen = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {  
                document.documentElement.requestFullScreen();  
            } else if (document.documentElement.mozRequestFullScreen) {  
                document.documentElement.mozRequestFullScreen();  
            } else if (document.documentElement.webkitRequestFullScreen) {  
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);  
            }  
        }else {  
            if (document.cancelFullScreen) {  
                document.cancelFullScreen();  
            } else if (document.mozCancelFullScreen) {  
                document.mozCancelFullScreen();  
            } else if (document.webkitCancelFullScreen) {  
                document.webkitCancelFullScreen();  
            }  
        } 
    }

    useEffect(() => {
        set_admin_link(window.Module.API.admin_path)
		set_mes_link(window.Module.API.mes_app_path)
    }, []);

    return (
        <React.Fragment>
            <div className='infurnia-navbar' id="navbarSupportedContent">
                {/* If Org does not have any logo then show Infurnia Logo */}
                <div className='inf-flex inf-items-center'><img className='infurnia-org-logo' src={store_details && store_details.logo_path ? store_details.logo_path : "/resources/images/infurnia_black.svg"} /></div>
                <div className='inf-flex inf-ml-auto inf-justify-end inf-items-center'>
                    <a href={admin_link} target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', marginBottom:'0px', padding:'8px', fontSize:'14px', marginRight:'16px', color:COLORS.black}} title="Admin portal" ><div className='flex_property'> <Icons name={'new_tab'} className='mr-2' style={{fontSize: '16px'}} /> {t(`Admin`)} </div></a>
					{
						store_details && store_details.active_add_ons && store_details.active_add_ons.length && store_details.active_add_ons.find(o => ['1', '4', '6', '7'].includes(o.id)) ?
						<a href={mes_link} target='_blank' className='hover_blue' style={{cursor:'pointer', lineHeight:'20px', marginBottom:'0px', padding:'8px 8px 8px 0px', fontSize:'14px', marginRight:'16px', color:COLORS.black}} title="Mes portal" ><div className='flex_property'> <Icons name={'new_tab'} className='mr-2' style={{fontSize: '16px'}} /> {t(`MES`)} </div></a>
						:''
					}
                    <div className='vl_end'></div>
                    <UncontrolledDropdown>
                        <DropdownToggle style={{ borderRadius: '50%', height: '32px', width: '32px', margin: '2px 0px', color: 'rgba(0, 120, 255, 1)', backgroundColor: 'rgba(0, 120, 255, 0.1)', border: '1px solid white', justifyContent: 'center', display: 'flex', alignItems: 'center', marginLeft: '8px', marginRight: '8px', textTransform: 'uppercase', fontWeight: 500 }}>{store_details && store_details.designer_name && store_details.designer_name.trim() ? store_details.designer_name.trim()[0] : ':)'}</DropdownToggle>
                        <DropdownMenu style={{ marginTop: '11px' }}>
                            <DropdownItem onClick={open_account_settings}>
                                Account Settings
                            </DropdownItem>
                            <DropdownItem onClick={logout}>
                                Logout
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    {window.Module.API.segment_id && (!(window.location.pathname.split('/')[1]) || (window.location.pathname.split('/')[1] != "livspace" && window.location.pathname.split('/')[1] != "zest"))?(
                        <img className='cp mx-2' onClick={() => {document.getElementById("my_custom_intercom_link").click()}} data-toggle="dropdown" data-placement="bottom" title="Need Help" src={'/resources/nav_icons_new/need_help.svg'}></img>
					):''}
                    {window.Module.API.segment_id && (!(window.location.pathname.split('/')[1]) || (window.location.pathname.split('/')[1] != "livspace" && window.location.pathname.split('/')[1] != "zest")) && (<button className="hover_toolbar infurnia-navbar-icons" style={{display:"none"}} id="my_custom_intercom_link"><img data-toggle="tooltip" data-placement="bottom" title="Need Help" src={'/resources/nav_icons_new/need_help.svg'}></img></button>)}
                    <button className="hover_toolbar infurnia-navbar-icons" onClick={toggle_fullscreen}><img data-toggle="tooltip" data-placement="bottom" title="Toggle Fullscreen" src={'/resources/nav_icons_new/fullscreen.svg'}></img></button>
                    <button className="hover_toolbar infurnia-navbar-icons" onClick={logout}><img data-toggle="tooltip" data-placement="bottom" title="Logout" src={'/resources/nav_icons_new/logout.svg'}></img></button>
                </div>

            </div>
            <Account_Settings_Modal open={show_account_settings} handle_close={close_account_settings} store_details={store_details} set_page_loader={set_page_loader}></Account_Settings_Modal>
        </React.Fragment>
    )
}

export default InfurniaMainNavbar;