import { Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';

const TriggerTooltip = ({trigger, title, titleParser, ...props}) => {
    const getTitle = () => {
        if(titleParser){
            return titleParser(title)
        }

        if(Array.isArray(title)){
            return (
                <div style={{whiteSpace: 'nowrap', minWidth: '225px'}}>
                    {
                        title.map((line, idx) => {
                            if(typeof(line) === "string"){
                                return line
                            }else if(Array.isArray(line)){
                                return (
                                    <div className='flex_property inf-gap-1' style={{marginBottom: '8px'}}>
                                        {
                                            line.map(item => (
                                                <div style={{ fontSize: item.font_size, color: item.text_color, fontWeight: item.font_weight, fontStyle: item.italic ? 'italic' : '', flex: item.flex, flexBasis: item.width, minWidth: '0px'}}>{item.text}</div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        }) 
                    }
                </div>
            )
        }
        
        return title
    }
    return ( 
        <Tooltip title={getTitle(title)} {...props}>
            {
                trigger ? trigger
                :
                <div><img src='/resources/icons/default_tooltip_trigger.svg' width={'16px'} height={'16px'} /></div>
            }
        </Tooltip>
     );
}
 
export default TriggerTooltip;