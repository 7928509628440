import React, { useState, useEffect } from 'react';
import { Input, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Row, Col, Modal, Card, Button, NavItem } from "reactstrap";
import { COLORS } from '../../utils/color';
import './style.css';
import { IModalBody, IModalFooter, IModalHeader } from '../../shared_components/ui_component_library/IModal';
import Icons from '../../shared_components/ui_component_library/Icons';

const type_to_message = {
    elevation: 'Elevation View',
    custom_plan: 'Cusotm Plan'
}

const Edit_elevation_view_Modal = ({ open, handle_close, active_elevation_view, type }) => {

    const [updated_elevation_view_name, set_updated_elevation_view_name] = useState('')

    const on_click_close = () => {
        set_updated_elevation_view_name('')
        handle_close()
    }

    const submit_values = () => {
        handle_close()
        window.Module.updateElevationCameraNameById(active_elevation_view.id, updated_elevation_view_name);
        console.log(updated_elevation_view_name);
        console.log(active_elevation_view.id);
        //write update name code here
    }

    useEffect(() => {
        if (open && active_elevation_view) {
            set_updated_elevation_view_name(active_elevation_view.name);
        }
    }, [open])

    return (
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={on_click_close}>
            <IModalHeader toggle={on_click_close}>
                Edit {type_to_message[type]} Name
            </IModalHeader>
            <IModalBody>
                <div className='mb-3'>
                    <label htmlFor={"Elevation View Name"} style={{ display: 'block', marginBottom: '0.5rem' }}>{`${type_to_message[type]} Name`}</label>
                    <Input
                        type="text"
                        id={"Elevation View Name"}
                        name={"Elevation View Name"}
                        value={updated_elevation_view_name}
                        placeholder={`Enter Elevation View Name`}
                        onChange={(e) => set_updated_elevation_view_name(e.target.value)}
                    />
                </div>
            </IModalBody>
            <IModalFooter>
                <Button className='primary_button_default' onClick={submit_values}>Update</Button>
            </IModalFooter>
        </Modal>

    )
}

const SingleViewItem = ({ update_view, item, on_select, submit_for_delete_confirm, open_edit_modal, component_name, type, handle_ui_response }) => {

    const deleteView = (id, e) => {
        window.Module.delete_elevation_camera_by_id(id);
        update_view();
    }

    const on_click_edit = (e) => {
        e.stopPropagation();
        open_edit_modal(item, type)
    }

    const onClickDeleteView = async(e) => {
        e.stopPropagation();
        // submit_for_delete_confirm(() => deleteView(item.id), () => { }, type === 'elevation' ? 'Elevation View will be deleted permanently.' : 'Custom plan will be deleted permanently.')
        let resp = await window.Promisify(window.Module.delete_elevation_camera_onclick(item.id))
        handle_ui_response(resp)
    }

    return (
        <div id={item.id} onClick={() => { on_select(item.id); }} className='dropdown_visibility_on_hover' style={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '36px', padding: '4px 8px' }}>
            <div className='dropdown_visibility_on_hover lines1_elipsis mr-2' style={{ cursor: 'pointer', flex: 5, minWidth: '40px' }} title={item.name}>{item.name}</div>
            <div style={{ flex: 1, marginLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {/* <i style={{ cursor: 'pointer' }} onClick={(e) => on_click_edit(e)} className='fa fa-pencil dropdown_item_hide scale_120 mr-2' />
                <i style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); submit_for_delete_confirm(() => on_click_delete(item.id), () => { }, 'Elevation View will be deleted permanently.') }} className='fa fa-trash dropdown_item_hide scale_120' /> */}
                <Icons name={'edit'} style={{ fontSize: '16px', cursor: 'pointer' }} className='blue_hover dropdown_item_hide mr-2' onClick={(e) => on_click_edit(e)} />
                <Icons name={'delete'} style={{ fontSize: '16px', cursor: 'pointer', color: '#FF5247' }} className='dropdown_item_hide' onClick={onClickDeleteView} />
            </div>
        </div>
    )
}

const FillerEmptyViews = ({ message }) => {
    return (
        <div className='filler_empty_views'>
            {message}
        </div>
    )
}

const MenuViews = ({heading, createRoute, options, fillerMessage, update_view, on_select, submit_for_delete_confirm, open_edit_modal, type, handle_ui_response, ...props}) => {
    return (
        <div {...props}>
            <div className='mb-2 flex_property justify-content-between px-2 py-1'>
                <div style={{ fontStyle: 'italic', color: COLORS.gray5}}><span style={{ textDecoration: 'underline' }}>{heading}</span>&nbsp;&nbsp;({options && options.length ? options.length : 0})</div>
                <div style={{ fontStyle: 'italic', color: '#0078ff', cursor: 'pointer' }} onClick={createRoute}>+Create</div>
            </div>
            <div style={{ maxHeight: '200px', overflow: 'auto', cursor: 'pointer' }}>
                {

                    options && options.length ? options.map((item, idx) => (
                        <DropdownItem key={idx} style={{ cursor: 'pointer', padding: 0, borderRadius: '4px' }}>
                            <SingleViewItem update_view={update_view} item={item} on_select={on_select} submit_for_delete_confirm={submit_for_delete_confirm} open_edit_modal={open_edit_modal} type={type} handle_ui_response={handle_ui_response}/>
                        </DropdownItem>
                    ))
                        :
                        <FillerEmptyViews message={fillerMessage} />
                }
            </div>
        </div>
    )
}

const NavBar_Dropdown = ({ id, update_view, className, elevation_options, custom_plan_options, before_open, on_select, submit_for_delete_confirm, active, handle_ui_response }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [open_edit_name_modal, set_open_edit_name_modal] = useState(false);
    const [active_elevation_view, set_active_elevation_view] = useState('');
    const [type, set_type] = useState('elevation');

    useEffect(() => {
        if (dropdownOpen && before_open) {
            before_open();
        }
    }, [dropdownOpen])

    const open_edit_modal = (active, type='elevation') => {
        set_open_edit_name_modal(true)
        set_active_elevation_view(active)
        set_type(type)
    }
    const close_edit_modal = () => {
        set_open_edit_name_modal(false)
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const addElevationView = () => {
        toggle();
        if (window.debug_mode) {
            window.add_debug_log("add_elevation_view_frontend(\"\");");
        }
        window.Module.add_elevation_view_frontend("");
        update_view();
    }

    const addCustomPlanView = () => {
        toggle();
        if (window.debug_mode) {
            window.add_debug_log("add_section_view_frontend(\"\");");
        }
        window.Module.add_section_view_frontend("");
        update_view();
    }

    const getDisplayName = () => {
        try{
            let current_view_info = JSON.parse(window.Module.get_current_camera_info());
            if(current_view_info && current_view_info.type){
                return current_view_info.type === 'section' ?
                    <> <Icons name={'custom_plan'} /> Custom Plan</>
                    : <> <Icons name={'elevation_view'} /> Elevation</>
            }
        }catch(err){
            console.log(err)
        }

        return "Elevation"
    }

    return (
        <React.Fragment>
            <Edit_elevation_view_Modal open={open_edit_name_modal} handle_close={close_edit_modal} active_elevation_view={active_elevation_view} type={type}/>
            <Dropdown id={id} style={{ padding: '8px', display: 'flex', alignItems: 'center', height: '28px' }} nav isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className={className} style={{ fontSize: '12px', padding: '0px', height: '100%', border: 'unset', boxShadow: 'unset', background: 'unset' }}>
                    {
                        active ?
                            <div className='perspective_item perspective_item_active'>
                                {getDisplayName()}
                                <i style={{ fontSize: '12px', padding: '2px' }} className='fa fa-caret-down'></i>
                            </div>
                            :
                            <div className={`perspective_item ${dropdownOpen ? 'perspective_item_semi_active' : ''}`} style={{}}>
                                <img width={'16px'} src='/resources/icons/options.svg'></img>
                            </div>
                    }
                </DropdownToggle>
                <DropdownMenu right style={{ padding: '8px', borderRadius: '6px', fontSize: '12px', width: '200px', marginTop: '8px', marginLeft: '4px' }}>
                    <MenuViews className='mb-2' heading={"Elevations"} createRoute={addElevationView} options={elevation_options} fillerMessage={"No Elevation Views"} update_view={update_view} submit_for_delete_confirm={submit_for_delete_confirm} on_select={on_select} open_edit_modal={open_edit_modal} type={'elevation'} handle_ui_response={handle_ui_response}/>
                    <DropdownItem divider></DropdownItem>
                    <MenuViews heading={"Custom plans"} createRoute={addCustomPlanView} options={custom_plan_options} fillerMessage={"No Custom plans"} update_view={update_view} submit_for_delete_confirm={submit_for_delete_confirm} on_select={on_select} open_edit_modal={open_edit_modal} type={'custom_plan'} handle_ui_response={handle_ui_response}/>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}



export default NavBar_Dropdown;


/* <li id="editing_building_nav" className="dropdown navfade" style={{paddingTop:'12px',paddingLeft: '3px'}}>
   <a className="dropdown-toggle blue_hover" data-toggle="dropdown" style={{display:'flex',padding:'0px',paddingLeft:'10px'}}>
       <span style={{color:'white',cursor:'pointer'}}>Building</span>
       <span><i className="fa fa-chevron-right" style={{color: 'white',margin:'auto auto auto 5px'}}></i></span>  
   </a>
   <ul className="dropdown-menu" style={{border: '1px solid black'}}>
       {([2,2,2,2]).map((o,idx) => <li role="presentation" ng-repeat="building in design_buildings"><a style={{cursor:'pointer'}} tabindex="-1">Yo</a></li>)}
   </ul>
</li>*/