import React, { useState, useEffect } from 'react';
import { ISingleSelectRSD } from "../ISingleSelect";
import sleep from '../../utils/sleep';
import { COLORS } from '../../utils/color';
import Multiselect1 from '../Multiselect';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import CarouselImageBox from '../Slider/ImageBox';
import PenguinSlider from '../Slider';
import Filter_Modal from './Filter';
import Filter_Info_Modal from './Filter_Info';
import { Checkbox, Tooltip } from 'antd';
import { DummyCarousel } from './DummyCarousel';
import Icons from '../../shared_components/ui_component_library/Icons';

const CarouselFilterDropdown = ({options, toggleText, menuAlignment, value, callbackOnChange, callbackOnAdd, callbackOnRemove, clearFilter, indeterminateState, ...props}) => {

    const onOptionClick = (item) => {

        if(callbackOnChange){
            let selectedOptions = value ? JSON.parse(JSON.stringify(value)) : [];
            if(selectedOptions.includes(item.id)){
                callbackOnChange(selectedOptions.filter(x => x != item.id));
                if(callbackOnRemove){
                    callbackOnRemove(item)
                }
            }else{
                selectedOptions.push(item.id)
                callbackOnChange(selectedOptions)
                if(callbackOnAdd){
                    callbackOnAdd(item)
                }
            }
        }
    }


    return(
        <UncontrolledDropdown className='DMSans' direction='up' {...props}>
            <DropdownToggle style={{border: 'none', boxShadow: 'none', backgroundColor: 'white', color: COLORS.gray6, display: 'flex', alignItems: 'center'}}>
                <div style={{ flex: 1, minWidth: '20px', fontSize: '12px', fontWeight: 'bold', fontStyle: 'italic' }} > {toggleText ? toggleText : "Select an Option"} </div>
                {value && value.length ? <div className='inf-ml-2 flex_center' style={{background: COLORS.gray3, padding: '2px', borderRadius: '4px', fontSize: '12px'}}><div className='inf-px-1'>{value.length}</div><Icons onClick={(e) => {if(callbackOnChange){e.stopPropagation(); clearFilter()}}} className='inf-px-1' name={'cross'} /></div> : ''}
                <i className={'fa fa-angle-up ml-3'}></i>
            </DropdownToggle>
            <DropdownMenu right={menuAlignment==="right"} style={{maxHeight: '320px', overflow: 'auto'}} className='DMSans' container={'body'}>
                {
                    options && options.length ? options.map(item => (
                        <DropdownItem toggle={false} className='' onClick={() => { onOptionClick(item) }} style={{ display: "flex", padding: '8px', borderRadius: '4px' }}>
                            <Checkbox className='mr-2' indeterminate={indeterminateState ? indeterminateState[item.id] && !(value && value.includes(item.id)) : false} checked={value && value.includes(item.id)} />
                            <div style={{flex: 1, minWidth: 0}}>
                                <div className='flex_between'>
                                    <div className='lines1_elipsis mr-2' title={item ? item["name"] : ''} style={{flex: 1, minWidth: 0}}>{item ? item["name"] : ''}</div>
                                    {item["hits"] ? <div style={{color: COLORS.gray4}}>({item["hits"]})</div> : ''}
                                </div>
                                { item && item["category_name"] ? <div className='mt-1' style={{color: COLORS.gray4}}>In {item["category_name"]}</div> : '' }
                            </div>
                        </DropdownItem>

                    ))
                        :
                        <DropdownItem className=''>No Items Found</DropdownItem>
                }
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

const DisplayCarousel = ({ panel_pop_up_show, sub_category, carousel_active_tab, update_view, handle_ui_response, onclick, onclick_stringified_json, set_page_loader, carousel_minimize, update_carousel_minimize, perspective, carousel_search_mode }) => {
    const [selected_sku, set_selected_sku] = useState('')
    const [carousel_data, set_carousel_data] = useState('')


    useEffect(() => {
        // console.log('sub_category=============>',sub_category)
        fetchInitialData();
    }, [sub_category, carousel_active_tab])

    const fetchInitialData = () => {
        var available_carousel_width;
        let carousel_container = document.getElementById('infurnia-carousel-container')
        if (carousel_container && carousel_container.clientWidth) {
            available_carousel_width = carousel_container.clientWidth
        } else {
            available_carousel_width = document.body.clientWidth
        }
        var width_of_one_card = 220;
        var initial_number_of_cards_needed_to_fill_screen = Math.floor(available_carousel_width / width_of_one_card) * 3;
        // var initial_number_of_cards_needed_to_fill_screen = Math.ceil(available_carousel_width/width_of_one_card);
        // console.log("initial_number_of_cards_needed_to_fill_screen: "+initial_number_of_cards_needed_to_fill_screen);
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        var data = active_view.get_carousel_data(0, initial_number_of_cards_needed_to_fill_screen, carousel_active_tab, "");
        // console.log("get_carousel_data " + "start_index: " + 0 + "items: " + initial_number_of_cards_needed_to_fill_screen + "sub_cat_index: " + carousel_active_tab + "search_string: " + search_string);
        set_carousel_data(JSON.parse(data));
        console.log(JSON.parse(data), 'data');
        active_design.delete();
        active_view.delete();
    }

    const fetchMoreData = () => {
        var available_carousel_width;
        let carousel_container = document.getElementById('infurnia-carousel-container')
        if (carousel_container && carousel_container.clientWidth) {
            available_carousel_width = carousel_container.clientWidth
        } else {
            available_carousel_width = document.body.clientWidth
        }
        var width_of_one_card = 220;
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        var data = JSON.parse(active_view.get_carousel_data(carousel_data.length, Math.floor(available_carousel_width / width_of_one_card), carousel_active_tab, ""));
        // console.log("get_carousel_data " + "start_index: " + carousel_data.length + "items: " + 10 + "sub_cat_index: " + carousel_active_tab + "search_string: " + search_string);
        // console.log("fetching more data: ",carousel_data.concat(JSON.parse(data)));
        set_carousel_data(JSON.parse(JSON.stringify(carousel_data)).concat(data));

        active_design.delete();
        active_view.delete();

        return data.length
    }

    return (
        carousel_data && !carousel_data.length ? 
            <div style={{ height: '100%', color: COLORS.gray4, fontWeight: 500 }} className='flex_center'>
                {carousel_search_mode ? `Couldn't find any results for your search query. Try refining your search query` : "No SKU found"}
            </div>
            :
        <PenguinSlider id={'penguin_slider_' + sub_category.name} infinite_scroll={true} fetchMoreData={fetchMoreData}>
            {carousel_data && carousel_data.length ? carousel_data/*.sort(sort_by)*/.map((element, idx) => (
                <CarouselImageBox
                    key={idx}
                    idx={sub_category.value + idx}
                    sku_json={element.sku ? element.sku : ''}
                    handle_ui_response={handle_ui_response}
                    update_view={update_view}
                    name={element.name}
                    disable_overlay={true}
                    route={onclick}
                    route_data={onclick_stringified_json}
                    set_page_loader={set_page_loader}
                    panel_pop_up_show={panel_pop_up_show}
                    image_style={{
                        maxWidth: 160,
                        maxHeight: 185,
                        height: 'unset',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        objectFit: 'contain',
                        display: 'block',
                        boxShadow: '0 0 8px 0 rgba(66,66,66,0.5)'
                    }}
                    // container_style={{minWidth: '228px', maxWidth: '228px'}}
                    footer_text={element.name ? element.name : 'NoName'}
                    carousel_minimize={carousel_minimize}
                    update_carousel_minimize={update_carousel_minimize}
                    perspective={perspective}
                />
            )) : 'No Data'}
        </PenguinSlider>
    )
}

const DisplaySKUCarousel = ({ carousel_sku_json, route, route_data, handle_ui_response, update_view, repopulate_panel, repopulate_panel_pop_up, update_carousel_minimize, set_page_loader, perspective, panel_pop_up_show }) => {

    const onclick_close_carousel = () => {
        // set_search_string("");
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.hide_carousel();
        update_view();
        active_design.delete();
        active_view.delete();
    }


    const carousel_onclick = async (e, selected_sku_id) => {
        // const on_click = async (e) => {
        if (route == 'dummy_carousel_onclick') {
            //do something about parameter modal
            console.log('trigger add sku to parameter json')
            window.set_sku_value(selected_sku_id)
            onclick_close_carousel()
        } else {
            set_page_loader({
                show: true,
                text: 'Please Wait...'
            })
            setTimeout(async () => {
                var promise_resp;
                try {
                    if (route_data == "") {
                        if (window.debug_mode) {
                            window.add_debug_log(route + "(\"" + selected_sku_id + "\");");
                        }
                        promise_resp = window.Module[route](selected_sku_id);
                    } else {
                        var parsed_route_data = JSON.parse(route_data);
                        parsed_route_data.sku_id = selected_sku_id;
                        if (window.debug_mode) {
                            window.add_debug_log(route + "(\"" + JSON.stringify(parsed_route_data).replace(/"/g, '\\"') + "\");");
                        }
                        promise_resp = window.Module[route](JSON.stringify(parsed_route_data));
                    }

                } catch (err) {
                    if (err.constructor.name.includes("Error") != true) err = new Error(err);
                    err.name = "Captured error in Carousel menu onclick error at route=" + route + ", route_data=" + route_data + ", selected_sku_id=" + selected_sku_id + " : " + err.name;
                    console.error(err);
                    window.sentry_capture_exception("", err);
                    alert.error("Something went wrong")
                    set_page_loader({
                        show: false,
                        text: 'Please wait'
                    });
                    return;
                }
                var response = await window.Promisify(promise_resp);

                handle_ui_response(response);
                if (panel_pop_up_show) {
                    repopulate_panel_pop_up();
                } else {
                    repopulate_panel();
                }
                // is_search_mode ? populate_panel_search(search_string_panel) : ''
                update_carousel_minimize(true);
                update_view();

                if (perspective == '3d') {
                    window.three_interface.mousemove(e)
                }
                else {
                    window.two_interface.mousemove(e)
                }

                set_page_loader({
                    show: false,
                    text: 'Please Wait...'
                })
            }, 0)
        }

        // }
    }

    return (
        <div className='flex_property display_sku_carousel'>
            {
                carousel_sku_json && carousel_sku_json.length ? carousel_sku_json.map(sku => (
                    <div className='p-2 single_carousel_sku_parameter cp' onClick={(e) => carousel_onclick(e, "@sku@_" + (sku && sku.label))}>
                        {/* <div>{sku && sku.name}</div> */}
                        <img src={sku && sku.icon}></img>
                        <div className='lines1_elipsis' title={`${sku && sku.name} (${sku && sku.sku_name})`}>{`${sku && sku.name} (${sku && sku.sku_name})`}</div>
                    </div>
                ))
                    : ''
            }
        </div>
    )
}


const BottomCarousel = (
    {
        active_category_id,
        set_active_category_id,
        carousel_active_tab,
        set_carousel_active_tab,

        carousel_eligible_categories,
        carousel_subcategories,

        carousel_sku_parameters_show,
        set_carousel_sku_parameters_show,
        carousel_sku_json,

        carousel_onclick,
        carousel_onclick_stringified_json,

        carousel_search_mode,
        carousel_search_string,
        set_carousel_search_string,
        carousel_search_category_filter_options,
        carousel_search_category_filter_value,
        set_carousel_search_category_filter_value,
        carousel_search_sub_category_filter_options,
        carousel_search_sub_category_filter_value,
        set_carousel_search_sub_category_filter_value,

        caraousel_loading,
        set_caraousel_loading,
        carousel_minimize,

        modal_type,
        update_carousel_json,
        update_view,
        handle_ui_response,
        set_page_loader,

        perspective,
        panel_pop_up_show,
        repopulate_panel,
        repopulate_panel_pop_up
    }) => {

    const [ show_carousel_filter_modal , set_show_carousel_filter_modal] = useState(false);
    const [ show_carousel_filter_info_modal , set_show_carousel_filter_info_modal] = useState(false);
    const [ visibility_index, set_visibility_index ] = useState(-1);
    const [ carousel_search_string_active, set_carousel_search_string_active ] = useState('');
    const [ carousel_search_category_inderminate_state, set_carousel_search_category_inderminate_state ] = useState({});

    const set_carousel_sku_parameters_show_true = () => {
        set_carousel_sku_parameters_show(true)
    }

    const set_carousel_sku_parameters_show_false = () => {
        set_carousel_sku_parameters_show(false)
    }

    const close_carousel_filter_modal = () => {
        set_show_carousel_filter_modal(false);
    }

    const open_carousel_filter_modal = () => {
        set_show_carousel_filter_modal(true);
    }

    const close_carousel_filter_info_modal = () => {
        set_show_carousel_filter_info_modal(false);
    }

    const open_carousel_filter_info_modal = () => {
        set_show_carousel_filter_info_modal(true);
    }

    const change_carousel_category = async (id) => {
        set_active_category_id(id);
        set_caraousel_loading({
            show: true,
            text: 'Fetching your Inventory'
        }, async () => {
            //await dummy_tick(0);
            await sleep(50)
            var active_design = window.Module.get_active_design();
            var active_view = active_design.active_view;

            active_view.change_carousel_category(id);

            active_design.delete();
            active_view.delete();
            update_view();
            set_caraousel_loading({
                show: false,
                text: 'Please wait'
            })
        })
    }

    const change_filter_search_result = async (search_string, sub_category_filter, category_filter) => {
        set_caraousel_loading({
            show: true,
            text: 'Fetching your Inventory'
        },
            async () => {
                await sleep(10)
                let active_design = window.Module.get_active_design();
                let active_view = active_design.active_view;
                let filter_json = {
                    sku_sub_category_id: sub_category_filter,
                    sku_category_id: category_filter
                }
                let resp = await window.Promisify(active_view.format_search_result(search_string, JSON.stringify(filter_json)));
                active_view.delete();
                active_design.delete()
                handle_ui_response(resp);
                set_caraousel_loading({ show: false })
            }

        )
    }

    const onclick_subcategory_button = (x) => {
        set_carousel_active_tab(x)
    }

    const update_carousel_minimize = (new_carousel_minimize) => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;

        active_view.update_carousel_minimize(new_carousel_minimize);
        update_carousel_json();

        active_design.delete();
        active_view.delete();
    }

    const onclick_minimize_carousel = () => {
        update_carousel_minimize(!carousel_minimize);
    }

    const onclick_close_carousel = () => {
        var active_design = window.Module.get_active_design();
        var active_view = active_design.active_view;
        active_view.hide_carousel();
        update_view();
        active_design.delete();
        active_view.delete();
    }

    const toggle_carousel_search_mode = async() => {
        let active_design = window.Module.get_active_design();
        let active_view = active_design.active_view;
        let resp = await window.Promisify(active_view.toggle_carousel_search_mode());
        active_view.delete();
        active_design.delete();
        update_carousel_json();
    }
    

    const is_carousel_above_modal = () => {
        return ["cam_flow","single_cabinet_cam_flow_add_connector","single_cabinet_cam_flow_change_connector","single_cabinet_cam_flow_edit_connector","single_cabinet_cam_flow_remove_connector", "cam_applicators","common_panel_modal","presentation_select_page", "hometown_vendor_info_modal", "sku_bucket_selector_modal", 'add_parameters_modal', 'fulfillment_tag_template_settings_modal', 'page_template'].includes(modal_type)
    }

    const sub_category_wheel_handler = (e) => {
        e.stopPropagation();
        if(e.deltaY != 0 || e.deltaX != 0){
            var element = window.document.getElementById("subcategories_carousel_col");
            if(element){
                if(e.deltaX != 0){
                    element.scrollLeft += e.deltaX;
                }else {
                    element.scrollLeft += e.deltaY;
                }
            }
        }
    }

    useEffect(() => {
        let subcategory_nav = document.getElementById("subcategories_carousel_col");
        let allowed_width = 0;
        if(subcategory_nav){
            allowed_width = subcategory_nav.clientWidth - 100;
        }
        let visibility_index = -1;
        let current_width = 0;
        for(let i in carousel_subcategories.sub_categories){
            let tab_id = carousel_subcategories.sub_categories[i].value + "_dummy";
            let tab = document.getElementById(tab_id);
            if(tab){
                let tab_width = tab.clientWidth + 8;
                if(tab_width + current_width < allowed_width){
                    current_width += tab_width;
                    visibility_index++;
                }else{
                    break;
                }
            }
        }
        set_visibility_index(visibility_index)
    }, [carousel_subcategories]);

    useEffect(() => {
        if(carousel_search_mode){
            let search_input = document.getElementById("infurnia_carousel_search_input");
            if(search_input){
                search_input.focus()
                const inputLength = search_input.value.length;
    			search_input.setSelectionRange(inputLength, inputLength);
            }
        }
    }, [carousel_search_mode]);

    return (
        <React.Fragment>
            <Filter_Modal open={show_carousel_filter_modal} handle_close={close_carousel_filter_modal} update_carousel_json={update_carousel_json} />
            <Filter_Info_Modal open={show_carousel_filter_info_modal} handle_close={close_carousel_filter_info_modal} carousel_subcategories={carousel_subcategories} />
            
            <div className='DMSans carousel_animation' style={{ position: 'absolute', zIndex: is_carousel_above_modal() ? 10000 : 1000, bottom: 'calc( var(--inf-bottom-carousel-height) + var(--inf-bottom-carousel-nav-height))', transform: carousel_minimize ? 'translateY(var(--inf-bottom-carousel-height))' : '', left: '0px' }} >
                {
                    (
                        <React.Fragment>
                            <div className='inf-flex' style={{ gap: '8px', display: carousel_search_mode ? 'none' : 'flex' }}>
                                <div className={`${carousel_sku_parameters_show ? 'inactive_carousel_tab ' : ''}infurnia_carousel_header inf-px-2`} style={{ width: '270px', borderTopRightRadius: '6px' }}>
                                    {/* {
                                    carousel_search_mode ?
                                        <div className='inf-my-1'>
                                            <div className={'infurnia_search_component_container_ideal'} style={{margin: '0px'}}>
                                                <span><i style={{ paddingLeft: '12px', paddingBottom: '3px', fontSize: '12px', color: '#9aa5b5' }} className='fa fa-search' /></span>
                                                <input className={'infurnia_search_component_inner_container'} placeholder={'Type to Search'} type='text' onKeyDown={
                                                (e) => {
                                                    if (e.key == 'Enter') {
                                                        e.target.blur();
                                                        let active_design = window.Module.get_active_design();
                                                        let active_view = active_design.active_view;
                                                        let resp = window.Promisify(active_view.format_search_result(e.target.value, active_category_id));
                                                        active_view.delete();
                                                        active_design.delete()
                                                        handle_ui_response(resp);
                                                    }
                                                }}
                                                ></input>
                                            </div>
                                        </div>
                                        : ''
                                } */}
                                    <div className='flex_property' style={{ gap: '8px' }} onClick={() => { if (carousel_sku_parameters_show) { set_carousel_sku_parameters_show_false() } }}>
                                        <div style={{ cursor: 'pointer', flex: 1, minWidth: '0px', borderRadius: '4px', background: '#4C4C4C' }}>
                                            <div style={{ margin: '0px', pointerEvents: carousel_sku_parameters_show ? 'none' : 'all' }}>
                                                {/* <Input style={{ backgroundColor: 'inherit', border: 'none', color: carousel_sku_parameters_show ? COLORS.black : COLORS.white, padding: '0px 8px', fontSize: '12px'}} value={active_category_id} type="select" onChange={(e) => change_carousel_category(e.target.value)}>
                                                {
                                                    carousel_eligible_categories && carousel_eligible_categories.length && carousel_eligible_categories.map((element, idx) => (
                                                        <option style={{ color: COLORS.black }} key={idx} value={element.id}>{element.name}</option>
                                                    ))
                                                }
                                            </Input> */}
                                                <ISingleSelectRSD options={carousel_eligible_categories} value={active_category_id} onOptionClick={(option) => { change_carousel_category(option.id) }} placeholder={"Categories"} styles={{ dropdownMenu: { width: '210px', margin: '4px 0px', height: '320px', fontSize: '12px', fontFamily: "'DM Sans', sans-serif", zIndex: is_carousel_above_modal() ? 10000 : 1000 }, dropdownItem: { fontSize: '12px' }, dropdownToggle: { background: '#36373F', color: 'white', fontWeight: 600 } }} />

                                            </div>
                                        </div>
                                        <div onClick={() => {
                                            toggle_carousel_search_mode()

                                            // set_carousel_search_mode(!carousel_search_mode)
                                        }}
                                            style={{ height: '36px', width: '36px', cursor: 'pointer', background: carousel_search_mode ? 'white' : '' }}
                                            className='flex_center'
                                        >
                                            <img src={carousel_search_mode ? '/resources/panel_icons_new/search_selected.svg' : '/resources/panel_icons_new/search.svg'}></img>

                                        </div>
                                    </div>
                                </div>
                                <div className={`${!carousel_sku_parameters_show ? 'inactive_carousel_tab ' : ''}flex_center infurnia_carousel_header`} style={{ display: carousel_sku_json && carousel_sku_json.length ? 'flex' : 'none', border: 'none', color: 'white', padding: "4px 12px", cursor: 'pointer', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', height: '44px', alignSelf: 'end' }} onClick={set_carousel_sku_parameters_show_true}>
                                    SKU Parameters
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            </div>

            <div style={{ position: 'fixed', zIndex: '-1', visibility: 'hidden', bottom: '-50px', display: 'flex' }}>
                {
                    carousel_subcategories && carousel_subcategories.sub_categories && carousel_subcategories.sub_categories.length ? carousel_subcategories.sub_categories.map((x, idx) => {
                        return (
                            <div key={idx} className={`carousel_sub_category_tab ${idx == carousel_active_tab ? 'active' : ''}`} id={x.value+"_dummy"} >
                                <div>{x.name ? x.name : ""}</div>
                                <div style={{ width: '24px', height: '24px', borderRadius: '4px', display: 'flex', alignItems: "center", justifyContent: "center", fontSize: '12px', backgroundColor: idx === carousel_active_tab ? COLORS.gray3 : "#323338", color: idx === carousel_active_tab ? COLORS.black : 'white', padding: '2px' }}>{x.count}</div>
                            </div>
                        )
                    })
                        : ''
                }
            </div>

            <div id='infurnia-carousel-container' className={`${carousel_minimize ? 'carousel_minimized ' : ''}infurnia_carousel_body DMSans carousel_animation`} style={{ position: 'absolute', zIndex: is_carousel_above_modal() ? 10000 : 1000, bottom: '0px', left: '0px', width: '100%', transform: carousel_minimize ? 'translateY(var(--inf-bottom-carousel-height))' : ''}} >
                {
                    (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='flex_property' style={{ height: 'var(--inf-bottom-carousel-nav-height)', gap: '16px', backgroundColor: COLORS.gray8  }} onClick={() => { update_carousel_minimize(false) }}>
                                <div id='subcategories_carousel_col' style={{flex: 1, minWidth: 0, height: '100%'}}>
                                {
                                    (carousel_sku_parameters_show || caraousel_loading.show) && !carousel_search_mode ?
                                        <div style={{ flex: 1, minWidth: '0px', height: '100%' }}></div>
                                        :
                                        !carousel_search_mode ?
                                            <div id="" style={{ textAlign: 'left', padding: '0px', display: "flex", gap: '8px', flex: 1, minWidth: '0px', height: '100%', paddingTop: '6px' }}>
                                                {
                                                    carousel_subcategories && carousel_subcategories.sub_categories && carousel_subcategories.sub_categories.length ? carousel_subcategories.sub_categories.map((x, idx) => {
                                                        return (
                                                            idx <= visibility_index ?
                                                            <div key={idx} className={`carousel_sub_category_tab ${idx == carousel_active_tab ? 'active' : ''}`} id={x.value} onClick={() => onclick_subcategory_button(idx)} >
                                                                <div>{x.name ? x.name : ""}</div>
                                                                <div style={{ width: '24px', height: '24px', borderRadius: '4px', display: 'flex', alignItems: "center", justifyContent: "center", fontSize: '12px', backgroundColor: idx===carousel_active_tab ? COLORS.white : "#323338", color: idx===carousel_active_tab ? COLORS.black : 'white', padding: '2px' }}>{x.count}</div>
                                                            </div>
                                                            :''
                                                        )
                                                    })
                                                        : ''
                                                }
                                                {
                                                    carousel_subcategories && carousel_subcategories.sub_categories && carousel_subcategories.sub_categories.length - 1 > visibility_index ?
                                                        <div className='flex_property' style={{flex: 1, justifyContent: 'end', minWidth: '0px'}}>
                                                            <ISingleSelectRSD options={carousel_subcategories.sub_categories.filter((x, idx) => idx > visibility_index)} value={carousel_subcategories.sub_categories[carousel_active_tab] ? carousel_subcategories.sub_categories[carousel_active_tab].value : ''} onOptionClick={(item) => onclick_subcategory_button(carousel_subcategories.sub_categories.findIndex((x) => x.value === item.value))} optionIdentifier='value' displayValue='name' placeholder={'Search sub-category'} customToggle={<div className='flex_property' style={{maxWidth: '100%', gap: '8px', background: carousel_active_tab > visibility_index  ? '#31323A' : '', paddingRight: '8px'}}>{carousel_active_tab > visibility_index ? <div style={{display: 'block'}} className={`carousel_sub_category_tab active lines1_elipsis`}>{carousel_subcategories.sub_categories[carousel_active_tab] ? carousel_subcategories.sub_categories[carousel_active_tab]["name"] : ''}</div> : ""}<img className='inf-px-1' style={{paddingBottom: '6px'}} src='/resources/icons/top_arrow.svg'></img></div>} customProps={{dropdownMenu: {right:true}}} styles={{ dropdown:{height: '100%', display: 'flex', flex: 1, minWidth: 0}, dropdownMenu:{maxHeight: '300px', height: `calc(59px + ${(carousel_subcategories.sub_categories.length - 1 - visibility_index)*33}px`, overflow: 'auto', fontFamily: "'DM Sans', sans-serif", zIndex: is_carousel_above_modal() ? 10000 : 1000}, dropdownToggle: {padding: '0px', background: 'transparent', justifyContent: 'end'}}} direction="up"></ISingleSelectRSD>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            :
                                            <div className='flex_property inf-px-2' style={{ flex: 1, minWidth: '0px', height: '100%', color: '#C5C7CF', gap: '12px' }}>
                                                <Icons className='inf-px-1' onClick={toggle_carousel_search_mode} name={'chevron_left'} style={{color: '#C5C7CF', fontSize: '18px', cursor: 'pointer'}} />
                                                <div style={{background: '#C5C7CF', width: '2px', borderRadius: '2px', height: 'calc( 100% - 16px )'}}></div>
                                                <div className=''>
                                                    <div className={'infurnia_search_component_container_ideal'} style={{ margin: '0px', borderRadius: '4px' }}>
                                                        <span><i style={{ paddingLeft: '12px', paddingBottom: '3px', fontSize: '12px', color: '#9aa5b5' }} className='fa fa-search' /></span>
                                                        <input id='infurnia_carousel_search_input' className={'infurnia_search_component_inner_container'} placeholder={'Search any SKU'} type='text' value={carousel_search_string} onChange={(e) => { set_carousel_search_string(e.target.value) }} onKeyDown={
                                                            (e) => {
                                                                if (e.key == 'Enter') {
                                                                    e.target.blur();
                                                                    change_filter_search_result(e.target.value, null, null)
                                                                    set_carousel_search_category_filter_value([])
                                                                    set_carousel_search_sub_category_filter_value([])
                                                                    set_carousel_search_category_inderminate_state({})
                                                                    set_carousel_search_string_active(e.target.value)
                                                                }

                                                                if(e.key == 'Escape'){
                                                                    toggle_carousel_search_mode()
                                                                }
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div style={{background: '#C5C7CF', width: '2px', borderRadius: '2px', height: 'calc( 100% - 16px )'}}></div>
                                                {
                                                    ((carousel_search_category_filter_options && carousel_search_category_filter_options.length) || (carousel_search_sub_category_filter_options && carousel_search_sub_category_filter_options.length)) ? 
                                                        <div className='flex_property' style={{gap: '8px'}}>
                                                            <div>
                                                                Search Filters:
                                                            </div>
                                                            
                                                            <CarouselFilterDropdown options={carousel_search_category_filter_options} value={carousel_search_category_filter_value} toggleText={"Category"} indeterminateState={carousel_search_category_inderminate_state}
                                                                callbackOnChange={(selectedOptions) => {
                                                                    set_carousel_search_category_filter_value(selectedOptions)
                                                                    // change_filter_search_result(carousel_search_string, carousel_search_sub_category_filter_value, selectedOptions)
                                                                }}
                                                                callbackOnAdd={(item) => {
                                                                    let selectedSubCats = carousel_search_sub_category_filter_value
                                                                    selectedSubCats = selectedSubCats.concat(carousel_search_sub_category_filter_options.filter(x => x.category_id === item.id).map(x => x.id))
                                                                    selectedSubCats = Array.from(new Set(selectedSubCats))
                                                                    // selectedSubCats = (carousel_search_sub_category_filter_options.filter(x => selectedSubCats.includes(x.id)))
                                                                    set_carousel_search_sub_category_filter_value(selectedSubCats)
                                                                    if(carousel_search_category_inderminate_state[item.id]){
                                                                        carousel_search_category_inderminate_state[item.id] = false
                                                                        set_carousel_search_category_inderminate_state(JSON.parse(JSON.stringify(carousel_search_category_inderminate_state)))
                                                                    }
                                                                    change_filter_search_result(carousel_search_string, selectedSubCats, [])

                                                                }}

                                                                callbackOnRemove={(item) => {
                                                                    let selectedSubCats = carousel_search_sub_category_filter_options.filter(x => carousel_search_sub_category_filter_value.includes(x.id))
                                                                    selectedSubCats = selectedSubCats.filter(x => x.category_id != item.id).map(x => x.id)
                                                                    set_carousel_search_sub_category_filter_value(selectedSubCats)
                                                                    if(carousel_search_category_inderminate_state[item.id]){
                                                                        carousel_search_category_inderminate_state[item.id] = false
                                                                        set_carousel_search_category_inderminate_state(JSON.parse(JSON.stringify(carousel_search_category_inderminate_state)))
                                                                    }
                                                                    change_filter_search_result(carousel_search_string, selectedSubCats, [])

                                                                }}

                                                                clearFilter={() => {
                                                                    set_carousel_search_category_filter_value([])
                                                                    set_carousel_search_sub_category_filter_value([])
                                                                    set_carousel_search_category_inderminate_state({})
                                                                    change_filter_search_result(carousel_search_string, [], [])

                                                                }}

                                                             />
                                                            
                                                            <CarouselFilterDropdown options={carousel_search_sub_category_filter_options} value={carousel_search_sub_category_filter_value} toggleText={"Sub Category"} 
                                                                callbackOnChange={(selectedOptions) => {
                                                                    set_carousel_search_sub_category_filter_value(selectedOptions)
                                                                    change_filter_search_result(carousel_search_string, selectedOptions, carousel_search_category_filter_value)
                                                                }}
                                                                callbackOnAdd={(item) => {
                                                                    let selectedItemCatId = item.category_id
                                                                    let itemWithSameCatId = carousel_search_sub_category_filter_options.filter(x => (x.category_id == selectedItemCatId && x.id != item.id)).map(x => x.id)
                                                                    let areAllItemsWithSelectedCatIdSelected = true;
                                                                    for(let i in itemWithSameCatId){
                                                                        let itemId  = itemWithSameCatId[i]
                                                                        if(!carousel_search_sub_category_filter_value.includes(itemId)){
                                                                            areAllItemsWithSelectedCatIdSelected = false
                                                                            break
                                                                        }
                                                                    }
                                                                    if(areAllItemsWithSelectedCatIdSelected){
                                                                        carousel_search_category_filter_value.push(selectedItemCatId)
                                                                        set_carousel_search_category_filter_value(JSON.parse(JSON.stringify(carousel_search_category_filter_value)))
                                                                        if(carousel_search_category_inderminate_state[selectedItemCatId]){
                                                                            carousel_search_category_inderminate_state[selectedItemCatId] = false
                                                                            set_carousel_search_category_inderminate_state(JSON.parse(JSON.stringify(carousel_search_category_inderminate_state)))
                                                                        }
                                                                    }

                                                                    if(!carousel_search_category_inderminate_state[selectedItemCatId]){
                                                                        carousel_search_category_inderminate_state[selectedItemCatId] = true
                                                                        set_carousel_search_category_inderminate_state(JSON.parse(JSON.stringify(carousel_search_category_inderminate_state)))
                                                                    }
                                                                }} 

                                                                callbackOnRemove={(item) => {
                                                                    let selectedItemCatId = item.category_id
                                                                    let indexOfCatIdInSelectedCatList = carousel_search_category_filter_value.indexOf(selectedItemCatId)
                                                                    if(indexOfCatIdInSelectedCatList != -1){
                                                                        carousel_search_category_filter_value.splice(indexOfCatIdInSelectedCatList, 1)
                                                                        set_carousel_search_category_filter_value(JSON.parse(JSON.stringify(carousel_search_category_filter_value)))
                                                                    }

                                                                    let selectedSubCategories = carousel_search_sub_category_filter_options.filter(x => (x.id != item.id && carousel_search_sub_category_filter_value.includes(x.id)))
                                                                    let isAlteastOneItemWithSelectedItemCatIdPresent = false;
                                                                    for(let i in selectedSubCategories){
                                                                        let selectedSubCategoryItem = selectedSubCategories[i]
                                                                        if(selectedSubCategoryItem.category_id == selectedItemCatId){
                                                                            isAlteastOneItemWithSelectedItemCatIdPresent = true;
                                                                            break
                                                                        }
                                                                    }

                                                                    if(carousel_search_category_inderminate_state[selectedItemCatId] != isAlteastOneItemWithSelectedItemCatIdPresent){
                                                                        carousel_search_category_inderminate_state[selectedItemCatId] = isAlteastOneItemWithSelectedItemCatIdPresent
                                                                        set_carousel_search_category_inderminate_state(JSON.parse(JSON.stringify(carousel_search_category_inderminate_state)))
                                                                    }
                                                                }} 

                                                                clearFilter={() => {
                                                                    set_carousel_search_category_filter_value([])
                                                                    set_carousel_search_sub_category_filter_value([])
                                                                    set_carousel_search_category_inderminate_state({})
                                                                    change_filter_search_result(carousel_search_string, [], [])
                                                                }}
                                                            />
                                                        </div>
                                                    :''
                                                }

                                            </div>
                                }
                                </div>
                                <div className='inf-px-2' style={{ display: 'flex', justifyContent: "flex-end", alignItems: "center", height: '100%', gap: '8px', background: COLORS.gray8 }}>
                                    {
                                        carousel_subcategories && carousel_subcategories.skus_filtered && carousel_subcategories.skus_filtered.length ?
                                            <Tooltip title={"Filtered SKUs"} placement='top'>
                                                <div id='inf_carousel_button_info' className='inf_carousel_action_buttons' onClick={open_carousel_filter_info_modal}>
                                                    <Icons name={'info'} />
                                                </div> 
                                            </Tooltip>
                                        : ''
                                    }
                                    {/* <Button style={{ borderRadius: '0px', backgroundColor: COLORS.white, color: COLORS.carousel_background, marginRight: '10px', fontSize: '12px', height: "28px" }} onClick={open_carousel_filter_modal}><i title='filter' className='fa fa-filter' ></i></Button> */}
                                    <div id='inf_carousel_button_filter' className='inf_carousel_action_buttons' onClick={open_carousel_filter_modal}>
                                        <Icons name={'filter'} />
                                    </div>
                                    {/* <Input style={{ borderRadius: '0px', fontSize: '13px', marginRight: '10px', height: "28px" }} type='text' placeholder="Search for..." value={search_string} onChange={(e) => search_carousel_onchange(e.target.value)} /> */}
                                    {/* <Button type="button" style={{ borderRadius: '0px', backgroundColor: COLORS.white, border: 'none', color: COLORS.carousel_background, fontSize: '14px', marginRight: '10px', height: "28px", display: 'flex', alignItems: 'center', justifyContent: "center" }} onClick={(e) => { e.stopPropagation(); onclick_minimize_carousel() }}><i title={carousel_minimize ? 'Maximize' : 'Minimize'} className={carousel_minimize ? 'fa fa-arrows-alt' : 'fa fa-window-minimize'}></i></Button> */}
                                    <div id='inf_carousel_button_minimize' className='inf_carousel_action_buttons' onClick={(e) => { e.stopPropagation(); onclick_minimize_carousel() }}>
                                        <Icons name={carousel_minimize ? 'maximize' : 'minimize'} />
                                    </div>
                                    {/* <Button type="button" style={{ borderRadius: '0px', backgroundColor: COLORS.white, border: 'none', color: COLORS.carousel_background, fontSize: '14px', height: "28px", display: 'flex', alignItems: 'center', justifyContent: "center" }} onClick={onclick_close_carousel}><i title='Close' className='fa fa-times'></i></Button> */}
                                    <div id='inf_carousel_button_cross' className='inf_carousel_action_buttons' onClick={onclick_close_carousel}>
                                        <Icons name={'cross'} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: 0, /*display: carousel_minimize ? 'none' : 'block',*/ height: 'var(--inf-bottom-carousel-height)', minHeight: '0px' }}>
                                {
                                    caraousel_loading.show ?
                                        <div style={{ height: '100%', fontSize: '24px', fontWeight: 700, color: 'white' }} className='flex_center'>
                                            {caraousel_loading.text}
                                            {/* <div style={{height: '100%', width: '100%', overflow: 'hidden'}}>
                                                <DummyCarousel customClassname={"inf_carousel_card_shine"}/>
                                            </div> */}
                                        </div>
                                        :
                                        !carousel_sku_parameters_show ?
                                            (
                                                carousel_search_mode && !carousel_search_string_active ?
                                                <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: COLORS.gray4, fontWeight: 500}}>
                                                    <div className='mb-2'>Search any SKU name or Model no, or Group name and press enter to show results</div>
                                                    <div>Press ESC to exit search mode</div>
                                                </div>
                                               :
                                                <DisplayCarousel
                                                    set_page_loader={set_page_loader}
                                                    handle_ui_response={handle_ui_response}
                                                    update_view={update_view}
                                                    sub_category={carousel_subcategories && carousel_subcategories.sub_categories && carousel_subcategories.sub_categories.length && carousel_subcategories.sub_categories[carousel_active_tab] ? carousel_subcategories.sub_categories[carousel_active_tab] : { data: [] }}
                                                    onclick={carousel_onclick}
                                                    onclick_stringified_json={carousel_onclick_stringified_json}
                                                    carousel_active_tab={carousel_active_tab}
                                                    carousel_minimize={carousel_minimize}
                                                    update_carousel_minimize={update_carousel_minimize}
                                                    perspective={perspective}
                                                    panel_pop_up_show={panel_pop_up_show}
                                                    carousel_search_mode={carousel_search_mode}
                                                />
                                            )
                                            :
                                            <DisplaySKUCarousel
                                                carousel_sku_json={carousel_sku_json}
                                                route={carousel_onclick}
                                                route_data={carousel_onclick_stringified_json}
                                                update_carousel_minimize={update_carousel_minimize}
                                                handle_ui_response={handle_ui_response}
                                                update_view={update_view}
                                                repopulate_panel={repopulate_panel}
                                                repopulate_panel_pop_up={repopulate_panel_pop_up}
                                                perspective={perspective}
                                                set_page_loader={set_page_loader}
                                                panel_pop_up_show={panel_pop_up_show}
                                            />
                                }
                            </div>
                        </div>
                    )
                }
            </div>

        </React.Fragment>
    );
}

export default BottomCarousel;