import React, { useEffect, useState } from "react";
import { ICollpsable } from "../ICollpsable";
import { isNonEmptyArray } from "../../utils/array";
import ApplicatorCard from "../../shared_components/cam_components/ApplicatorCard";
import Icons from "../../shared_components/ui_component_library/Icons";
import { Button, Select } from "antd";
import { useAlert } from 'react-alert'

const CamFlowRightPanelCard = ({ applicator }) => {
    const change_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_change_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const remove_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_remove_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const edit_on_click = async () => {
        var form = {}
        form["selected_connector_id"] = applicator.id;
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_edit_connector_modal(JSON.stringify(form)));
        window.handle_ui_response(resp);
    }

    return (
        <div className="rounded" style={{ border: '1px solid #6B4EFF', overflow: 'hidden' }}>
            <ApplicatorCard edit_selected_applicator={edit_on_click} o={applicator} dropdown_buttons={[
                {
                    display_name: "Change connector",
                    customOnClick: change_on_click
                },
                {
                    display_name: "Remove connector",
                    customOnClick: remove_on_click,
                    color: '#FF5247'
                }
            ]} />
        </div>
    )
}

const CamFlowRightPanel = ({ cam_mode_data, update_view, handle_ui_response }) => {

    const alert = useAlert();

    // const [ open_scenario, set_open_scenario ] = useState('');
    const [create_scenario_open, set_create_scenario_open] = useState(false);
    
    //create scenario parameters
    const [connection_type, set_connection_type] = useState('hinge');
    //panel 1 is always cam_mode_active_panel
    const [panel2, set_panel2] = useState('');
    const [face1, set_face1] = useState('');
    const [face2, set_face2] = useState('');

    const does_scenario_already_exist = () => {
        if(cam_mode_data && cam_mode_data.scenarios && cam_mode_data.scenarios.length){
            var scenario_find = cam_mode_data.scenarios.find(scenario => {
                if(scenario.attached_panels && scenario.attached_panels.length > 1){
                    if(scenario.attached_panels[0].id === cam_mode_data.cam_mode_active_panel && scenario.attached_panels[1].id === panel2){
                        return true;
                    }
                }

                return false
            })

            if(scenario_find){
                return true;
            }
        }
        return false;
    }


    useEffect(() => {
        set_connection_type('hinge');
        set_panel2('');
        set_face1('');
        set_face2('');
        set_create_scenario_open(false);
    }, [cam_mode_data])

    const handleChange = (val, property) => {
        if(property === 'connection_type'){
            set_connection_type(val);
        }else if(property === 'panel2'){
            set_panel2(val);
            window.Module.set_new_scenario_second_panel_interface(val);
        }else if(property === 'face1'){
            set_face1(val);
        }else if(property === 'face2'){
            set_face2(val);
        }
    }

    const onclick_create_scenario = () => {
        if(!does_scenario_already_exist()){
            if(!panel2){
                alert.error("Please select panel 2");
                return;
            }
            if(!face1){
                alert.error("Please select face 1");
                return;
            }
            if(!face2){
                alert.error("Please select face 2");
                return;
            }

            var form = {
                "connection_type": connection_type,
                "panel1": cam_mode_data.cam_mode_active_panel,
                "face1": face1,
                "panel2": panel2,
                "face2": face2
            }
            var resp = window.Module.create_new_scenario_from_cam_mode(JSON.stringify(form));
            handle_ui_response(resp);
        }
    }

    const toggle = (scenario) => {
        if(scenario == "create_scenario"){
            set_create_scenario_open(!create_scenario_open);
            window.Module.set_cam_mode_active_connector_set('create_scenario');
        }else{
            window.Module.set_cam_mode_active_connector_set(scenario.active ? '' : scenario.id);
        }
        window.three_interface.em_interface.needs_redraw = true;
        window.two_interface.em_interface.needs_redraw = true;
        update_view();
    }
    
    const add_on_click = async (applicator) => {
        var form = {}
        form["selected_connector_id"] = applicator.id;
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_add_connector_modal(JSON.stringify(form)));
        window.handle_ui_response(resp);
    }

    return (
        <>
            <div style={{ textAlign: 'left', width: '400px', background: 'white', position: 'absolute', top: 'var( --navbar_height_design , 52px)', right: '4px', zIndex: '999', height: 'calc(100vh - var( --navbar_height_design , 52px))' }}>
                {
                    cam_mode_data && cam_mode_data.cam_mode_active_panel ?
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div className="inf-py-2 inf-px-5" style={{ fontWeight: 'bold' }}>Scenario List</div>
                            <div style={{ flex: 1, minHeight: '0px', overflow: 'auto' }}>
                                <ICollpsable header={"Create new scenario"} toggle={() => toggle("create_scenario")} isOpen={create_scenario_open} style_content_inner={{padding:"10px 16px"}}>
                                    <div style={{ overflow: 'hidden', display:"flex", flexFlow:"column",fontSize:"14px",gap:"16px" }}>
                                        <div style={{display:"grid",gridTemplateColumns:"1fr 2fr",alignItems:"center"}}>
                                            <div>
                                                Connection Type:
                                            </div>
                                            <div>
                                                <Select name="connection_type" label="Connection type" value={connection_type} onChange={(val) => handleChange(val, 'connection_type')} style={{width:"100%"}}>
                                                    <option value="hinge">Hinge</option>
                                                    <option value="dowel">Dowel</option>
                                                </Select>
                                            </div>
                                        </div>
                                        
                                        <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                            <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                                <div>Select first panel</div>
                                                <div style={{width:"16px", height:"16px", backgroundColor:"#FFD991"}}></div>
                                            </div>
                                            <div style={{display:"grid",gridTemplateColumns:"1fr 4fr"}}>
                                                <div>
                                                    Panel 1:
                                                </div>
                                                <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                                    <Select disabled={true} value={cam_mode_data.cam_mode_active_panel} style={{width:"100%"}}>
                                                        <option value={cam_mode_data.cam_mode_active_panel}>{cam_mode_data.cam_mode_active_panel_name}</option>
                                                    </Select>
                                                    <Select value={face1} onChange={(val) => handleChange(val, 'face1')} style={{width:"100%"}}>
                                                        <option value="">Select face</option>
                                                        <option value="top">Top</option>
                                                        <option value="bottom">Bottom</option>
                                                        <option value="left">Left</option>
                                                        <option value="right">Right</option>
                                                        <option value="back">Back</option>
                                                        <option value="front">Front</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                            <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                                                <div>Select second panel</div>
                                                <div style={{width:"16px", height:"16px", backgroundColor:"#FDB1D4"}}></div>
                                            </div>
                                            <div style={{display:"grid",gridTemplateColumns:"1fr 4fr"}}>
                                                <div>
                                                    Panel 2:
                                                </div>
                                                <div style={{display:"flex",flexFlow:"column",gap:"8px"}}>
                                                    <Select value={panel2} onChange={(val) => handleChange(val, 'panel2')} style={{width:"100%"}}>
                                                        <option value="">Select panel</option>
                                                        {
                                                            cam_mode_data && cam_mode_data.other_panels && cam_mode_data.other_panels.map(panel => (
                                                                <option value={panel.id}>{panel.name}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                    <Select value={face2} onChange={(val) => handleChange(val, 'face2')} style={{width:"100%"}}>
                                                        <option value="">Select face</option>
                                                        <option value="top">Top</option>
                                                        <option value="bottom">Bottom</option>
                                                        <option value="left">Left</option>
                                                        <option value="right">Right</option>
                                                        <option value="back">Back</option>
                                                        <option value="front">Front</option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <Button style={{fontSize:"12px"}} onClick={onclick_create_scenario} disabled={does_scenario_already_exist()} className='primary_button_default'>{does_scenario_already_exist()?"Scenario between selected panels already exists":"Create Scenario"}</Button>
                                    </div>
                                </ICollpsable>
                                {
                                    cam_mode_data && cam_mode_data.scenarios && cam_mode_data.scenarios.length ? cam_mode_data.scenarios.map((scenario, idx) => (
                                        // <div className={`mb-3 p-2 mx-2 mes_panel`} style={{ border: ((scenario.active)) ? '2px solid #676878' : '', }} onClick={() => { window.Module.set_cam_mode_active_connector_set(scenario.id); update_view(); }}>
                                        //     <div className='mb-2' style={{ color: COLORS.gray9, fontWeight: 700 }}>{"Scenario " + (idx + 1)}</div>
                                        // </div>
                                        <ICollpsable header={`Scenario ${idx + 1}`} toggle={() => toggle(scenario)} isOpen={scenario.active}>
                                            <div className="flex_column inf-gap-2">
                                                <div onClick={add_on_click} className="flex_property inf-gap-2 cp" style={{border: '1px solid #0078ff', borderRadius: '4px', padding: '4px 8px', color: '#0078ff'}}>
                                                    <Icons name={'add'}></Icons>
                                                    <div>Add new connector</div>
                                                </div>
                                                {
                                                    isNonEmptyArray(scenario.cam_applicator_ids) ? scenario.cam_applicator_ids.map(cam_applicator_id => (
                                                        <CamFlowRightPanelCard applicator={cam_mode_data && cam_mode_data.connectors && cam_mode_data.connectors.find(x => x.id === cam_applicator_id)} />
                                                    )) : ''
                                                }
                                            </div>
                                        </ICollpsable>
                                    )) : ''
                                }
                            </div>
                        </div>
                        :
                        <div className="inf-p-2" style={{ height: '100%' }}>
                            <div className="flex_center" style={{ height: '100%', background: '#EAECF0', border: '2px dashed #A7A8B2' }}>
                                <div style={{ maxWidth: '250px', fontWeight: 500, color: '#676878', fontSize: '16px', textAlign: 'center' }}>
                                    Select a panel on 3D viewer or on panel listing in left panel to see the panel connectors here
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}

export default CamFlowRightPanel