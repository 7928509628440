import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, Nav, Navbar, Row, UncontrolledDropdown } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import CreatableSelect from 'react-select/creatable'
import { useAlert } from 'react-alert'
import Switch from "react-switch";
import './style.css'

import { IModalBody, IModalFooter, IModalHeader } from '../../shared_components/ui_component_library/IModal';
import general_fetch from '../../utils/fetch';
import debug_log from '../../utils/debug_log';
import ISearch from '../ISearch';
import generic_sort from '../../utils/generic_sort';
import { COLORS } from '../../utils/color';
import is_valid_add_key from '../../utils/is_valid_add_key';
import KeyValuePair from '../KeyValuePair';
import { validate_design_name } from '../../utils/validate_name';
import changeProject from '../../utils/changeProject';
import {useTranslation} from 'react-i18next'
import { send_analytics } from '../../utils/send_analytics';
import Upload3dModel from '../Upload3dModel';
import upload_asset from '../../utils/upload_3d_model';
import sleep from '../../utils/sleep';
import { Checkbox, Tooltip } from 'antd';
import { handleError } from '../../utils/errors';

const _deref = (a) => (JSON.parse(JSON.stringify(a)));


export const Account_Settings_Modal = ({ open, handle_close, store_details, set_page_loader }) => {
	const history = useHistory();
	const alert = useAlert()
	const [all_store_options, set_all_store_options] = useState([]);
	const [display_store_options, set_display_store_options] = useState([]);
	const [selected_bu_json, set_selected_bu_json] = useState({});
	const [all_business_units, set_all_business_units] = useState();
    const {t} = useTranslation()

	const fetch_attached_stores = async () => {
		try {
			set_page_loader({ show: true, text: 'Fetching Orgs...' })
			let resp = await general_fetch({ url: '/user/get_attached_stores' })
			set_page_loader({ show: false })
			set_all_store_options(resp.stores.sort((a, b) => generic_sort(a, b, 'name')))
			set_all_business_units(resp.business_units)
			let a = {}
			if (resp.stores && resp.stores.length) {
				resp.stores.forEach(single_store => {
					if (single_store.business_unit_ids && single_store.business_unit_ids.length == 1) {
						a[single_store.id] = single_store.business_unit_ids[0]
					}
				})
				set_selected_bu_json(a)
			}
		} catch (err) {
			set_page_loader({ show: false })
			console.log(err)
		}
	}

	const switch_store_or_bu = async (store_id) => {
		if (store_id && selected_bu_json[store_id]) {
			try {
				set_page_loader({ show: true, text: 'Switching Org' })
				let resp1 = await general_fetch({ url: '/user/set_active_store', body: { selected_store_id: store_id } })
				let resp2 = await general_fetch({ url: '/user/set_current_business_unit', body: { business_unit_id: selected_bu_json[store_id] } })
				set_page_loader({ show: false })
				history.push('/')
				window.location.reload()
			} catch (err) {
				set_page_loader({ show: false })
				alert.error('Error while switching Business Unit')
				console.log(err)
			}
		} else {
			alert.error('Please select a Business Unit')
		}
	}

	const onclick_handleclose = () => {
		handle_close()
		set_all_store_options([])
		set_selected_bu_json({})
		set_all_business_units({})
	}

	const update_selected_bu_json = (store_id, bu_id) => {
		let a = JSON.parse(JSON.stringify(selected_bu_json))
		a[store_id] = bu_id
		set_selected_bu_json(a)
	}

	useEffect(() => {
		if (open) {
			fetch_attached_stores()
		}
	}, [open]);

	// useEffect(() => {
	//     console.log("store selected_bu_json", selected_bu_json)
	// }, [selected_bu_json]);

	return (
		<Modal className='modal-dialog-centered' isOpen={open} toggle={onclick_handleclose} size='lg'>
			<IModalHeader toggle={onclick_handleclose}>
				Account Settings
			</IModalHeader>
			<IModalBody style={{ height: '550px', overflow: 'auto' }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', flexDirection: 'row', margin: '12px 0px', padding: '0px 32px' }}>
						<span style={{ display: 'flex', alignItems: 'center', flex: 1, fontWeight: '700', fontSize: '16px' }}>User Details</span>                    </div>
					<div style={{ display: 'flex', flexDirection: 'row', margin: '12px 0px', padding: '0px 32px' }}>
						<span style={{ display: 'flex', alignItems: 'center', flex: 1 }}>Name</span>
						<div style={{ display: 'flex', alignItems: 'center', flex: 3, border: '1px solid rgba(197, 199, 207, 1)', padding: '6px 12px', color: 'rgba(103, 104, 120, 1)' }}>{store_details ? store_details.designer_name : ':)'}</div>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', margin: '12px 0px', padding: '0px 32px' }}>
						<span style={{ display: 'flex', alignItems: 'center', flex: 1 }}>Email Id</span>
						<div style={{ display: 'flex', alignItems: 'center', flex: 3, border: '1px solid rgba(197, 199, 207, 1)', padding: '6px 12px', color: 'rgba(103, 104, 120, 1)' }}>{store_details ? store_details.designer_email : ':)'}</div>
					</div>
				</div>
				{
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ display: 'flex', flexDirection: 'row', margin: '12px 0px', padding: '0px 32px' }}>
							<span style={{ display: 'flex', alignItems: 'center', flex: 1, fontWeight: '700', fontSize: '16px' }}>User Organizations</span>
							<div style={{ marginRight: '-16px' }}>
								<ISearch list={all_store_options} set_list={set_display_store_options} search_parameters={['name']} />
							</div>
						</div>
						<div style={{ padding: '0px 32px' }}>
							{
								display_store_options && display_store_options.length ? display_store_options.map((item, idx) => (
									<div key={idx} style={{ display: 'flex', border: '1px solid rgba(197, 199, 207, 1)' }}>
										<div style={{ flex: 2, display: 'flex', flexDirection: 'row', padding: '12px 32px' }}>{item.name}</div>
										<div style={{ flex: 2, display: 'flex', flexDirection: 'row', padding: '12px 32px' }}>
											{
												all_business_units && item && item.business_unit_ids && item.business_unit_ids.length > 1 ?
													<Input type='select' value={selected_bu_json[item.id]} onChange={(e) => update_selected_bu_json(item.id, e.target.value)} style={{ height: '28px', width: '150px', fontSize: '14px', padding: '0px 4px' }}>
														<option value=''>Select BU</option>
														{
															all_business_units && item && item.business_unit_ids && item.business_unit_ids.length ? item.business_unit_ids.map((business_unit) => (
																<option value={business_unit}>{all_business_units[business_unit] ? all_business_units[business_unit].name : ''}</option>
															)) : ''
														}
													</Input> : ''
											}
										</div>
										<div onClick={!(window.Module.get_active_user().store_id == item.id && window.Module.get_active_user().business_unit_id == selected_bu_json[item.id]) ? () => switch_store_or_bu(item.id) : null} style={{ flex: 1, display: 'flex', flexDirection: 'row', padding: '12px 32px', cursor: 'pointer', color: !(window.Module.get_active_user().store_id == item.id && window.Module.get_active_user().business_unit_id == selected_bu_json[item.id]) ? '#0078ff' : 'green' }}>{!(window.Module.get_active_user().store_id == item.id && window.Module.get_active_user().business_unit_id == selected_bu_json[item.id]) ? "Switch here" : "Active"}</div>
									</div>
								))
									:
									<div>{t(`No Stores`)}</div>
							}
						</div>
					</div>
				}
			</IModalBody>
		</Modal>
	)
}

const createOption = (label) => ({
	label,
	value: label,
	not_in_org: true
});

export const Share_invite_modal = ({ open, users, handle_close, invite_users_onclick }) => {
	const [ inputValue, setInputValue ] = useState();
	const [selected_users, set_selected_users] = useState([])
	const [role, set_role] = useState("write")
	const alert = useAlert();
    const {t} = useTranslation()

	useEffect(() => {
		if (open) {
			set_selected_users([]);
		}
	}, [open])

	const submit_values = () => {
		var user_data = []
		let user_data_not_in_org = []
		for (var i = 0; i < selected_users.length; i++) {
			if(selected_users[i].not_in_org){
				user_data_not_in_org.push({
					email: selected_users[i].value,
					role: role,
					label: selected_users[i].value,
					is_selected: true
				})
			}else{
				user_data.push({
					id: selected_users[i].id,
					role: role,
					is_selected: true,
					label: selected_users[i].label
				})
			}
		}

		invite_users_onclick(user_data, user_data_not_in_org);
	}

	const handleKeyDown = (event) => {
		if (!inputValue) return;
		switch (event.key) {
		  case 'Enter':
		  case 'Tab':
			set_selected_users((prev) => [...prev, createOption(inputValue)]);
			setInputValue('');
			event.preventDefault();
		}
	  };

	const handleOnChange = (selectedOptions) => {
		set_selected_users(selectedOptions);
	};

	return (
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={handle_close}>
			<IModalHeader toggle={handle_close}>Invite Designer</IModalHeader>
			<IModalBody>
				<div className='flex_property mb-3'>
					<div className='mr-3'>{t(`Email`)}</div>
					{/* <Typeahead
						multiple
						id="share_branch_typeahead"
						onChange={(selected) => {
							set_selected_users(selected);
						}}
						options={users}
						selected={selected_users}
					// style={{...styles_modals.input_styles}}
					/> */}
					<CreatableSelect
						id="share_branch_typeahead"
						styles={{
							container: (baseStyles, state) => ({
							  ...baseStyles,
							  width: '100%'
							}),
						  }}
						  classNames={{
							menu: () => "inf_share_branch_typeahead_menu",
						  }}
						isMulti
						isClearable
						inputValue={inputValue}
						// getOptionValue={(option) => option.id}
						value={selected_users}
						onChange={handleOnChange}
						options={users.map(x => {return {...x, value: x.id}})}
						// onKeyDown={handleKeyDown}
						onCreateOption={async(inputValue) => {
							// await window.Promisify(window.Module[modal_json.share_branch_onclick])
							if(inputValue && inputValue.endsWith('infurnia.com') && window.Module.validate_infurnia_email(inputValue)){
								set_selected_users((prev) => [...prev, createOption(inputValue)]);
							}else{
								alert.error("Not a valid emial")
							}
						}}
						isValidNewOption={(inputValue) => (inputValue && inputValue.endsWith('infurnia.com') && window.Module.validate_infurnia_email(inputValue))}
						formatCreateLabel={(inputValue) => (`Add ${inputValue}`)}
						formatOptionLabel={(data) => (<div className='flex_property'>{data.label} {data.value && data.value.endsWith('infurnia.com') ? <Tooltip placement='topLeft' overlayInnerStyle={{borderRadius: '8px'}} title={<div className='inf-p-2' style={{color: COLORS.gray5}}>You can add infurnia’s personnel as a free user to this design to help you with the issues. You wont be charged for this user.</div>}><div className='ml-2 inf_share_user_modal_infurnia_user_badge'>Not in ORG</div></Tooltip> : ''}</div>)}
						// formatOptionLabel={(data) => (<div className='flex_property'>{data.label} {data.id && data.id.endsWith('infurnia.com') ? <div className='ml-2 inf_share_user_modal_infurnia_user_badge'>Not in ORG</div> : ''}</div>)}

					/>
				</div>
				<div className='flex_property justify-content-between'>
					<div className='flex_property'>
						<div className='mr-3'>{t(`Access`)}</div>
						<Input style={{ fontSize: '12px', padding: '4px', width: 'fit-content' }} value={role} onChange={(e) => { set_role(e.target.value) }} type="select">
							<option value={"read"}>View</option>
							<option value={"write"}>Edit</option>
						</Input>
					</div>
					<Button onClick={submit_values} className="primary_button_default" type="button">Invite</Button>
				</div>
			</IModalBody>
		</Modal>
	)
}

export const Share_Branch_Modal_New = ({ org_key, open, handle_close, active_design }) => {
	const alert = useAlert();
	const [share_link, set_share_link] = useState('');
	const [current_sharing, set_current_sharing] = useState(false);
	const [share_collapse_open, set_share_collapse_open] = useState(false);
	const [selected_users, set_selected_users] = useState([])
	const [open_invite, set_open_invite] = useState(false);
	const [users, set_users] = useState([])
	const [modal_json, set_modal_json] = useState();

	const random_colors = ['#dd51c0', '#7fe11b', '#681b81', '#c2b5d0', '#5a5e23', '#376911', '#9c6ba5', '#5c96dd', '#347907', '#ddbb2', '#583fa0', '#453d21', '#a93626', '#77cdf', '#ae995', '#b5b3bc', '#eabf6e', '#4cb8a5', '#7a49fd', '#ade45e', '#2f1167', '#fd054a', '#fa953d', '#d0a9ce', '#51076a', '#ef1597'];
	const alphabets = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
    const {t} = useTranslation()

	useEffect(() => {
		if (open && modal_json && modal_json.share_link) {
			var new_share_link = modal_json.share_link;
			if (org_key) {
				var split = modal_json.share_link.split("share");
				split[0] += org_key + "/share"
				new_share_link = split[0] + split[1];
			}

			set_share_link(new_share_link);
			update_current_sharing();

			var users_deref = JSON.parse(JSON.stringify(modal_json.users));
			set_users(users_deref)
			var filtered_users = users_deref.filter(o => o.is_selected && o.is_selected == true);
			set_selected_users(filtered_users);

		}
	}, [open, modal_json])

	const update_current_sharing = async () => {
		var resp = await window.Promisify(window.Module.get_public_view_status_of_branch(active_design.current_design_branch_id));
		resp = JSON.parse(resp);
		set_current_sharing(resp);
	}

	const set_active_branch_link_sharing = async () => {
		var resp = await window.Promisify(window.Module.set_active_branch_link_sharing(!current_sharing, active_design.current_design_branch_id));
		update_current_sharing();
	}

	const copy_link = () => {
		try{
			navigator.clipboard.writeText(share_link)
			alert.success("Copied to clipboard!");
		} catch (err) {
			console.error("Error in Share Design ", err)
			alert.error("Could not copy link")
		}
	}

	const get_bg_color = (name) => {
		if (name && name.trim()) {
			var ct = name.trim()[0].toLowerCase();
			var index = alphabets.indexOf(ct);
			if (index > -1 && index < 25) {
				return random_colors[index];
			}
		}

		return "rgba(0, 120, 255, 1)";
	}

	const open_invite_modal = () => {
		set_open_invite(true)
	}

	const close_invite_modal = () => {
		set_open_invite(false)
	}

	const submit_values = async(final_users, user_data_not_in_org) => {
		var user_data = []
		for (var i = 0; i < final_users.length; i++) {
			user_data.push({
				user_id: final_users[i].id,
				role: final_users[i].role
			});
		}

		await window.Promisify(window.Module[modal_json.share_branch_onclick](active_design.current_design_branch_id, JSON.stringify({user_data, user_data_not_in_org})));
		update_modal_json()
	}

	const change_role = (user_id, new_role) => {
		var final_users = _deref(selected_users);
		if (new_role == "remove_access") {
			final_users = final_users.filter(o => o.id != user_id);
		} else {
			if (final_users.find(o => o.id == user_id)) {
				final_users.find(o => o.id == user_id).role = new_role;
			}
		}

		console.log(final_users);
		set_selected_users(final_users);
		submit_values(final_users);
	}

	const invite_users_onclick = (new_user_data, user_data_not_in_org) => {
		var final_users = _deref(selected_users);
		var final_users_in_org = _deref(selected_users);

		for (var i = 0; i < new_user_data.length; i++) {
			var user_find = final_users.find(o => o.id == new_user_data[i].id);
			if (user_find) {
				if (user_find.role != new_user_data[i].role) {
					user_find.role = new_user_data[i].role;
				}
			} else {
				final_users.push(new_user_data[i]);
			}

			user_find = final_users_in_org.find(o => o.id == new_user_data[i].id);
			if (user_find) {
				if (user_find.role != new_user_data[i].role) {
					user_find.role = new_user_data[i].role;
				}
			} else {
				final_users_in_org.push(new_user_data[i]);
			}
		}

		for (var i = 0; i < user_data_not_in_org.length; i++) {
			final_users.push(user_data_not_in_org[i]);
		}

		console.log("final users", final_users);
		set_selected_users(final_users);
		submit_values(final_users_in_org, user_data_not_in_org);
		close_invite_modal()
	}

	const update_modal_json = () => {
		if (active_design && active_design.current_design_branch_id) {
			let resp = window.Module.get_share_branch_json(active_design.current_design_branch_id, active_design.id)
			set_modal_json(JSON.parse(resp))
		}
	}

	useEffect(() => {
		update_modal_json()
	}, [active_design]);

	return (
		<Modal className="modal-dialog-centered" size="md" isOpen={open}>
			<Share_invite_modal open={open_invite} handle_close={close_invite_modal} users={users} invite_users_onclick={invite_users_onclick} />
			<IModalHeader toggle={handle_close}>Share Design</IModalHeader>
			<IModalBody>
				<div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '12px' }}>
					<div className='flex_property justify-content-between mb-3'>
						<div>
							Design sharing (common view access)
						</div>
						<div className='flex_property'>
							<div style={{ fontSize: '12px' }} className='mr-3'>
								{current_sharing ? 'Enabled' : 'Disabled'}
							</div>
							<Switch
								onChange={set_active_branch_link_sharing}
								checked={current_sharing}
								uncheckedIcon={false}
								checkedIcon={false}
								offColor='#e6e6e6'
								offHandleColor='#808080'
								onColor='#00924f'
								onfHandleColor='#b3d1ff'
								height={15}
								width={30}
							/>
						</div>
					</div>
					{
						current_sharing ? (
							<div className='flex_property'>
								<Input style={{ fontSize: '12px' }} id="share_link_input" value={share_link} readOnly="true" type="text"></Input>
								<img style={{ cursor: 'pointer' }} className='ml-3' src='/resources/icons/copy.svg' height={'24px'} onClick={copy_link} />
							</div>
						) : ''
					}
				</div>
				<hr />
				<div className='flex_property justify-content-between'>
					<div className='flex_property' style={{ cursor: 'pointer' }} onClick={() => { set_share_collapse_open(!share_collapse_open) }}>
						<div className='mr-3'>{t(`People with Access`)}</div>
						<i className={share_collapse_open ? 'fa fa-caret-up' : 'fa fa-caret-down'} />
					</div>
					<Button onClick={open_invite_modal} className='primary_button_default' type="button">Invite</Button>
				</div>
				<Collapse isOpen={share_collapse_open} className="py-3" style={{ width: "100%" }}>
					<div style={{ borderRadius: 10, backgroundColor: COLORS.white, maxHeight: '400px', overflow: 'auto' }} className="p-3">
						{selected_users.map(o => (
							<div className='flex_property justify-content-between my-2'>
								<div className='flex_property lines1_elipsis' style={{ flex: 1 }}>
									<div style={{ borderRadius: '50%', height: '32px', width: '32px', color: 'white', backgroundColor: get_bg_color(o.label), border: '1px solid white', textTransform: 'uppercase', fontWeight: 500 }} className='flex_center mr-3'>{o.label && o.label.trim() ? o.label.trim()[0] : ':)'}</div>
									<div className='mr-3'>{o.label}</div>
								</div>
								<Input value={o.role} onChange={(e) => { change_role(o.id, e.target.value) }} style={{ fontSize: '12px', width: 'fit-content', padding: '4px' }} type="select">
									<option value={"read"}>Can View</option>
									<option value={"write"}>Can Edit</option>
									<option value={"remove_access"} style={{ color: 'red' }}>Remove Access</option>
								</Input>
							</div>
						))}
					</div>
				</Collapse>
			</IModalBody>
		</Modal>
	)

}




// -------------------------------------------------------------------

const all_allowed_key = {
    custom_sku_code:{
        id:'custom_sku_code',
        name:'Custom Sku Code',
        type:'text'
    },
	order_id:{
        id:'order_id',
        name:'Order ID',
        type:'text'
    }
}
export const Addn_prop_modal = ({ open, update_design_additional_properties, handle_close, active_design }) => {

	const [ additional_json_item_default, set_additional_json_item_default ] = useState([]);
	const [ additional_json_item_custom, set_additional_json_item_custom ] = useState([]);
	const [addn_prop, set_addn_prop] = useState('');
	const alert = useAlert()

	const onclick_close = () => {
		handle_close('addn_prop')
		set_additional_json_item_default([])
		set_additional_json_item_custom([])
		set_addn_prop('')
	}

	const submit_values = () => {
		if(additional_json_item_default && additional_json_item_default.length>1 && !is_valid_add_key(additional_json_item_default)){
			alert.error("Empty or Duplicate key values not allowed in default properties.")
		}else if(additional_json_item_custom && additional_json_item_custom.length>1 && !is_valid_add_key(additional_json_item_custom)){
			alert.error("Empty or Duplicate key values not allowed in custom properties.")
		}
		else{
			var x_addi_prop = [];
			additional_json_item_default.map(x => {
				if(additional_json_item_default[0].key){
					x_addi_prop.push(x)
				}
			})
			additional_json_item_custom.map(x => {
				if(additional_json_item_custom[0].key){
					x_addi_prop.push(x)
				}
			})
			if(active_design){ 
				window.Module.update_design_additional_properties(active_design.id, JSON.stringify(x_addi_prop))
				alert.success("Updated Additional Properties")
			}
			handle_close('addn_prop')
		}
	}

	useEffect(() => {
		if(active_design && active_design.additional_properties){
			var x_default = [], x_custom=[];
			
			active_design.additional_properties.map(x_item => {
				if(Object.keys(all_allowed_key).includes(x_item.key)){
					x_default.push(x_item)
				}else{
					x_custom.push(x_item)
				}
			})
			
			set_additional_json_item_default(x_default)
			set_additional_json_item_custom(x_custom)
		}
	},[active_design])

	return(
		<Modal className="modal-dialog-centered" size="xl" isOpen={open} toggle={onclick_close}>
            {/* <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.modal_heading , color:COLORS.white}}>
                        <span>Additional Properties of {active_design?active_design.name:'-'}</span>
                        <span style={{cursor:'pointer'}} onClick={onclick_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'400px', overflow:'auto', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
						<Row style={{margin:0,marginTop:'5px', fontSize:'14px'}}>
							<Col xs={1}style={{padding:0, whiteSpace:'nowrap'}}>
								<span style={{fontWeight:'600'}}>Design Name :</span> {active_design?active_design.name:'-'}
							</Col>
						</Row>
						<Row style={{margin:0,marginTop:'5px', fontSize:'14px'}}>
							<Col xs={1}style={{padding:0 , whiteSpace:'nowrap'}}>
							<span style={{fontWeight:'600'}}>Design Sl No. :</span> {active_design?active_design.serial_number:'-'}
							</Col>
						</Row>
						<KeyValuePair item_json={additional_json_item_default} set_item_json={set_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key}/>
						<KeyValuePair item_json={additional_json_item_custom} set_item_json={set_additional_json_item_custom} mode='custom'/>
                    </Col>
                </Row>
				<Row style={{backgroundColor: COLORS.white, padding:'20px 24px', borderTop:'1px solid #f2f2f2'}}>
					<Col style={{textAlign:'right'}}>
						<Button onClick={onclick_close} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0, marginRight:'10px'}} type="button">Cancel</Button>
						<Button onClick={submit_values} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button">Update</Button>
					</Col>  
				</Row>
					
            </Card> */}
			<IModalHeader toggle={onclick_close}>
				Additional Properties of {active_design?active_design.name:'-'}
			</IModalHeader>
			<IModalBody style={{height:'450px', overflow:'auto', alignItems:'center'}}>
			{/* <Navbar>
                    <Nav>
                        <div style={{padding:'4px 16px 4px 0px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === '' ? '700' : '400', fontSize:'14px' }} onClick={() => set_addn_prop('')}>Default Properties</div>
                        <div style={{padding:'4px 16px 4px 16px', borderRight:'1px solid #E8E8E8', fontWeight:template_selection === 'org' ? '700' : '400', fontSize:'14px'}} onClick={() => set_addn_prop('custom')}>Custom Properties</div>
                    </Nav>
                    <Nav className="justify-content-end">
                        <Input id='template_search' style={{width:'200px', marginRight:'16px', fontSize:'12px', padding:'6px 12px 6px 12px' }} placeholder='Search the Template' type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></Input>
                    </Nav> */}
                    {/* <Nav className="justify-content-end">
                        <div></div>
                    </Nav> */}
                {/* </Navbar> */}
				<div style={{display:'flex', height:'400px', overflow:'auto', backgroundColor:'#F5F6F8', alignItems:'center', justifyContent:'space-around'}}>
					<div style={{ overflow:'auto', backgroundColor:'white', height:'80%', width:'45%'}}>
						<KeyValuePair item_json={additional_json_item_default} set_item_json={set_additional_json_item_default} mode='default' all_allowed_key={all_allowed_key}/>
					</div>
					<div style={{overflow:'auto', backgroundColor:'white', height:'80%', width:'45%'}}>
						<KeyValuePair item_json={additional_json_item_custom} set_item_json={set_additional_json_item_custom} mode='custom'/>
					</div>
				</div>
			</IModalBody>

			<IModalFooter style={{justifyContent:'center'}}>
				<Button style={{}} className='primary_button_default' onClick={submit_values}>Update</Button>
			</IModalFooter>
        </Modal>
	)    
}

export const Edit_Design_Modal = ({ open, rename_design, handle_close, active_design }) => {
	const alert = useAlert()
	useEffect(() => {
		if(active_design && active_design.name){
			set_design_name(active_design.name);
		}
	},[active_design]);

	const [ design_name, set_design_name ] = useState("");

	const onclick_close = () => {
		set_design_name('')
		handle_close('edit_design')
	}

	const submit_values = () => {
		if(active_design){
			try {
				if(validate_design_name(design_name)){
					rename_design(active_design.id, design_name)
					handle_close('edit_design')
				}else{
					alert.error('Special Characters Not permitted. Please modify the Design Name')
				}
			} catch (ee) {
				alert.error('Request Failed')
			}
		}
	}

	

	useEffect(() => {
		if(active_design && open){
			//console.log("================================================", active_design)
			set_design_name(active_design.name?active_design.name:'')
		}
	},[active_design])

	return(
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_close}>
            {/* <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.modal_heading , color:COLORS.white}}>
                        <span>Edit Design</span>
                        <span style={{cursor:'pointer'}} onClick={onclick_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'186px', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:1, fontSize:'14px'}}>Design Name*</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}} type="text" value={design_name} onChange={(e) => set_design_name(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:'center',marginTop:'30px'}}>
                                <Button onClick={submit_values} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button">Update</Button>
                            </Col>  
                        </Row>
                    </Col>
                </Row>
            </Card> */}
			<IModalHeader toggle={onclick_close}>
				Edit Design
			</IModalHeader>
			<IModalBody>
				<div className='new_project_modal_div'>
                    <span style={{width:'120px' , display:'flex', alignItems:'center'}}>Design Name*</span>
                    <Input style={{width:'280px'}} type='text' placeholder='Design Name' value={design_name} onChange={(e) => set_design_name(e.target.value)}></Input>
                </div>
			</IModalBody>

			<IModalFooter style={{justifyContent:'center'}}> 
                <Button style={{marginRight:'32px'}} className='primary_button_default' onClick={submit_values}>Update</Button>
            </IModalFooter>
        </Modal>
	)    
}

export const Advanced_copy_modal = ({open, onclick_close, onclick_submit, active_design, active_project_id, set_page_loader, copy_design, user_id, project_item}) => {

	let advanced_options_fields = [
		{id: 'sku_data', name: 'SKU Data', info: 'Pricing and additional properties, etc.'},
		{id: 'rules', name: 'Rules', info: 'Includes Hardware rules, Design automation, Design constraints, etc.'},
		{id: 'preferences', name: 'Preferences', info: 'Default settings for Pricing Quotation, Cutlist, Elevation, etc.'},
		{id: 'cam_settings', name: 'CAM settings', info: 'Tool settings related to board layout and CNC machines.'}
	]

	const [advanced_options, set_advanced_options] = useState({})

	const onSubmit = () => {
		debug_log("Advanced Options - ", advanced_options, project_item)
		onclick_submit(project_item, advanced_options);
		onclick_close();
	}

	const reset = () => {
		try{
			set_advanced_options({})
		}catch(err){
			console.error(`Error in reset `, err)
			handleError(err);
		}
	}

	useEffect(() => {
		if(open){
			reset()
		}
	}, [open]);

	return (
		<Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={onclick_close}>
			<IModalHeader toggle={onclick_close}>
				Advanced paste option
			</IModalHeader>
			<IModalBody>
					<div style={{marginBottom: '16px', color: '#676878'}}>By default these values will be overridden from the admin settings. Choose what you want to preserve from the design.</div>

						<div>
						{advanced_options_fields && advanced_options_fields.length && advanced_options_fields.map(item =>
							<div style={{marginBottom: '16px', display: 'flex', gap: '4px'}}>
								<Checkbox onChange={(e) => {advanced_options[item.id] = e.target.checked;}}>{item.name}</Checkbox>
								<Tooltip title={item.name} placement='right'>
									<img src='/resources/icons/info2.svg' style={{cursor: 'pointer'}}/>
								</Tooltip>
							</div>
						)
						}
						</div>
			</IModalBody>

			<IModalFooter style={{justifyContent:'center', gap: '16px', marginLeft: 'auto'}}> 
				<Button className='white_button_auto' style={{borderRadius: '6px'}} onClick={onclick_close}>Back</Button>
                <Button style={{}} className='primary_button_default' onClick={onSubmit}>Paste in {project_item && project_item.name}</Button>
            </IModalFooter>
        </Modal>
	)
}

export const Copy_Design_Modal = ({ open, handle_close, active_design, active_project_id,set_page_loader, copy_design, user_id }) => {

	const alert = useAlert()
	const [ all_project_list, set_all_project_list] = useState([])
	const [ all_project_list_display, set_all_project_list_display] = useState([])
	const [ search_string, set_search_string] = useState('')
	const [advanced_modal, set_advanced_modal] = useState(false);
	const [advanced_copy_project, set_advanced_copy_project] = useState('')

    const fetch_projectDetails = () => {
		set_page_loader({
			show: true,
			text: 'Fetching projects...'
		});

		setTimeout(async() => {  
			try{
			var response = await window.Promisify(window.Module.getProjects())
			var response_obj = response && JSON.parse(response)
			console.log("project info", response_obj)
			set_all_project_list_display(response_obj&& response_obj.length?response_obj:'')
			set_all_project_list(response_obj&& response_obj.length?response_obj:'')

			set_page_loader({
				show: false,
				text: 'Please wait'
			});	
			}catch(err){
				if(window['sentry_capture_exception']){
					window['sentry_capture_exception']("",err);
				}
			}
		},0)
	}
	
	useEffect(() => {
		var temp_list=[...all_project_list];
		if(search_string){
			var temp_list = temp_list.filter((project) => project && project.name && project.name.toLowerCase().includes(search_string) )
		}
		set_all_project_list_display(temp_list)
	},[search_string])

	useEffect(() => {
		if(active_design && open){
			fetch_projectDetails()
		}
	},[active_design])

	const onclick_close = () => {
		handle_close('copy_design')
	}

	const onclick_submit = (all_project_list_display_item, advanced_paste_options) => {
		if(active_design && active_design.current_design_branch_id && all_project_list_display_item && all_project_list_display_item.id){
			copy_design(active_design.current_design_branch_id,all_project_list_display_item.id, "Copy of "+active_design.name, advanced_paste_options );
		}
		handle_close('copy_design');
	}

	return(
		<>
		<Advanced_copy_modal 
		open={advanced_modal}
		onclick_close={() => {set_advanced_modal(!advanced_modal); set_advanced_copy_project('');}}
		onclick_submit={onclick_submit}
		active_design={active_design}
		copy_design={copy_design}
		active_project_id={active_project_id}
		set_page_loader={set_page_loader}
		user_id={user_id}
		project_item={advanced_copy_project}
		/>
		<Modal className="modal-dialog-centered" size="lg" isOpen={open} toggle={onclick_close}>
			{/* <Card className="bg-secondary shadow border-0">
				<CardHeader style = {{backgroundColor: COLORS.modal_heading , color:COLORS.white}} >
					<div className="text-muted">
						<Row>
							<Col xs={7}style={{color:COLORS.white}}>Copy Design to a Project</Col>
							<Col xs={4}><Input style={{height:'25px', width:'100%' }} placeholder="Search Project" type="text" bsSize='small' value={search_string} onChange={(e) => set_search_string(e.target.value)} /></Col>
							<Col xs={1} style={{textAlign:'right', color:COLORS.white, curser:'pointer'}} onClick={onclick_close}>X</Col>
						</Row>
					</div>
				</CardHeader>
				<CardBody style = {{backgroundColor:COLORS.white, padding:"24px 30px" }} >
					<table style={{height:'100%', width:'100%', fontSize:'13px'}}>
		                <thead>
		                    <tr style={{display:'flex', height:'30px', alignItems:'center', borderBottom:'1px solid #ddd'}}>
		                        <th style={{flex:1}}><span><b>#</b></span><br /></th>
		                        <th style={{flex:5}}><span><b>Project Name</b></span><br /></th>
		                        <th style={{flex:5}}><span><b>Target Project</b></span> <br /></th>
		                    </tr>
		                </thead>
		                <tbody>
		                    {
		                        all_project_list_display && all_project_list_display.length ? all_project_list_display.map((all_project_list_display_item, index) => (
		                            <tr key={index} className='table_custom_strip' style={{display:'flex', borderTop:'1px solid #ddd', height:'50px', alignItems:'center'}}>
		                                <td style={{flex:1}}>{index+1}</td>
		                                <td style={{flex:5}}>{all_project_list_display_item.name?(all_project_list_display_item.id === active_project_id?all_project_list_display_item.name + " (Active)":all_project_list_display_item.name):"-"}</td>
		                                <td style={{flex:5}}><Button onClick={() => onclick_submit(all_project_list_display_item)} className="btn" style={{color: 'black', backgroundColor:COLORS.light_grey, display:'flex', alignItems:'center', justifyContent:"center", height:'25px'}} >Copy Here</Button></td>
		                            </tr>
		                        )):null
		                    }
		                </tbody>
		            </table>
				</CardBody>
			</Card> */}
			<IModalHeader toggle={onclick_close}> Paste Destination</IModalHeader>
			<IModalBody>
				<Navbar>
					<Nav>
						<div style={{fontSize:'16px'/* , fontWeight:'bold' */}}>Only Owned Projects are displayed</div>
					</Nav>
					<Nav className='justify-content-end'>
						<Input style={{height:'32px', width:'300px', fontSize:'12px'}} placeholder="Search Project" type="text" bsSize='small' value={search_string} onChange={(e) => set_search_string(e.target.value)} />
					</Nav>
				</Navbar>
				<div style={{height:'450px', overflow:'auto', margin:'0px 16px'}}>
				<table style={{fontSize:'12px', width:'100%', position:'relative', backgroundColor:'white'}}>
					<thead style={{position:'sticky', top:'0', backgroundColor:'white'}}>
						<tr style={{display:'flex', height:'30px', alignItems:'center'}}>
							<th style={{flex:5, color:'rgba(167, 168, 178, 1)'}}><span>Destination Projects</span></th>
							<th style={{flex:5, color:'rgba(167, 168, 178, 1)', textAlign:'center'}}><span>Action</span></th>
							<th style={{flex: 2}}><span></span></th>

						</tr>
					</thead>
					<tbody style={{}}>
						{
							all_project_list_display && all_project_list_display.length ? all_project_list_display.filter(all_project_list_display_item => all_project_list_display_item.user_id == user_id).map((all_project_list_display_item, index) => (
								<tr key={index} className='table_custom_strip show_on_hover' style={{display:'flex', borderTop:'1px solid #ddd', height:'50px', alignItems:'center'}}>
									<td style={{flex:5}}>{all_project_list_display_item.name?(all_project_list_display_item.id === active_project_id?all_project_list_display_item.name + " (Active)":all_project_list_display_item.name):"-"}</td>
									<td style={{flex:5, color:'rgba(0, 120, 255, 1)', textAlign:'center', cursor:'pointer'}} onClick={() => {all_project_list_display_item.user_id == user_id ?onclick_submit(all_project_list_display_item):alert.error('Cannot Copy design to Non owned Project')}} className='hide'>Paste Here</td>
									<td style={{flex:3, whiteSpace: "nowrap", color:"#0078ff", textAlign:'center', cursor:'pointer'}} className='hide'>
										<div onClick={(all_project_list_display_item.user_id == user_id) ? () => {set_advanced_modal(true); set_advanced_copy_project(all_project_list_display_item);} : () => {alert.error('Cannot Copy design to Non owned Project')}}>Advanced paste option</div>
										{/* <UncontrolledDropdown className='class_position_unset'>
										<DropdownToggle className='hide' outline style={{ outline: 'unset', padding: 'unset', border: 'unset', boxShadow: 'unset', display: 'flex', alignItems: 'center', background: 'transparent', padding: '2px 6px 2px 2px', maxHeight: '24px', aspectRatio: '1'}} onClick={e => {e.stopPropagation();}}>
											<img src='/resources/icons/options.svg'></img>
										</DropdownToggle>
										<DropdownMenu style={{position: 'absolute', paddingTop: '6px', paddingBottom: '6px'}}>
											<DropdownItem onClick={(all_project_list_display_item.user_id == user_id) ? () => {set_advanced_modal(true); set_advanced_copy_project(all_project_list_display_item);} : () => {alert.error('Cannot Copy design to Non owned Project')}}>Advanced paste option</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown> */}
									</td>
									{/* <td style={{flex:5}}><Button onClick={() => onclick_submit(all_project_list_display_item)} className="btn" style={{color: 'black', backgroundColor:COLORS.light_grey, display:'flex', alignItems:'center', justifyContent:"center", height:'25px'}} >Copy Here</Button></td> */}
								</tr>
							)):null
						}
					</tbody>
				</table>
				</div>
			</IModalBody>

		</Modal>
		</>
	)    
}

// -------------------------------------------------------------------
const infurnia_addons = {
	5: { name: 'Advanced Pricing', priceInCredits: '125', features: ['Component Based Pricing', 'Raw Material Based Pricing', 'Standardised Construction Logic'] },
	4: { name: 'Panel Cut-List', priceInCredits: '125', features: ['Panel Cut-List & Bill of Quantity', 'Board Layout Optimizer', 'Panel Labels/Tags'] },
	6: { name: 'MES Lite', priceInCredits: '125', features: ['Panel Cut-List & Bill of Quantity', 'Board Layout Optimizer', 'Panel Labels/Tags', "Work Order Management & Tracking"] },
	1: { name: 'Manufacturing', priceInCredits: '250', features: ['Everything in Panel Cut-List Plan +', 'Drilling & routing outputs for Biesse, Homag & Felder machines'] },
	7: { name: 'MES Pro', priceInCredits: '250', features: ['Everything in MES Lite +', 'Drilling & routing outputs for Biesse, Homag & Felder machines', "Packaging and Assembly"] },
	2: { name: 'API Integration', priceInCredits: '250', features: ['CRM / ERP Integration', 'Cabinet Creation API', 'Custom APIs to suit your workflow'] },
	3: { name: 'White Labeling', priceInCredits: '250', features: ['Custom URL', 'Self-Branded Landing Page', 'Self-Branded Emails']},
}

const infurnia_base_plan = { name: 'Base Subscription', priceInCredits: '125', features: ['All Design Features including Architecture, interior & modular kitchen design', 'Working Drawings', 'Custom Catalog', 'Cloud-based rendering'] }


const AddonCard = ({addon, is_subscribed, pricingDetails}) => {
	return (
		<div style={{display: addon && [1,4].includes(addon.id) && !is_subscribed ? 'none' : ''}} className={`addon_card ${is_subscribed ? 'addon_subscibed' : ''}`}>
			<div className={`addon_name`}>
				{/* Addon Name */}
				{addon.name}
			</div>
			<div className='addon_pricing'>
				{/* Subscription status/Cost */}
				{is_subscribed ? 'Subscribed' : `${pricingDetails.currency === 'INR' ? '₹' : '$'} ${addon.priceInCredits * (pricingDetails.cost_per_credit ? pricingDetails.cost_per_credit : 1)} / mo`}
			</div>
			<div className='addon_features'>
				{/* Addon Details */}
				<ul style={{margin: '8px 0px'}}>
				{
					addon.features ? addon.features.map(feature => (
						<li>{feature}</li>
					))
					:''
				}
				</ul>
			</div>
		</div>
	)
}

export const InfurniaPlansModal = ({open, toggle}) => {

	const [infurniaAddons, setInfurniaAddons] = useState({});
	const [infurniaBasePlan, setInfurniaBasePlan] = useState({});
	const [pricingDetails, setPricingDetails] = useState({});

	const get_store_subscription_info = async() => {
		let response = await window.Promisify(window.Module.get_subscription_info())
		if(response){
			response = JSON.parse(response)
			return response.store_details
		}
		return null
	}


	useEffect(() => {
		if(open){
			(async () => {
			let info = await get_store_subscription_info()
			if(info && info.add_ons && info.add_ons.subscribed){
				for(let i in info.add_ons.subscribed){
					let addon = info.add_ons.subscribed[i]
					infurnia_addons[addon.id].priceInCredits = addon.credit_cost/3
					infurnia_addons[addon.id].subscribed = true
				}
			}

			if(info && info.add_ons && info.add_ons.subscribable){
				for(let i in info.add_ons.subscribable){
					let addon = info.add_ons.subscribable[i]
					infurnia_addons[addon.id].priceInCredits = addon.credit_cost/3
					infurnia_addons[addon.id].subscribed = false
				}
			}
			setInfurniaAddons(infurnia_addons)
			if(info){
				infurnia_base_plan.subscribed = info.premium_customer && !info.churned && !info.suspended
				if(info.ccr_details){
					setPricingDetails(info.ccr_details)
				}
			}
			setInfurniaBasePlan(infurnia_base_plan)
		})();
		}
	}, [open]);

	return(
		<Modal isOpen={open} toggle={toggle} className='modal-dialog-centered' style={{maxWidth: 'min(90%, 1400px)'}}>
			<IModalBody style={{padding: '2rem 4rem', borderRadius: '6px', position: 'relative'}}>
				<img onClick={toggle} style={{cursor:'pointer', position: 'absolute', right: '2rem', top: '2rem'}} height={'16px'} width={'16px'} src={'/resources/icons/modal_cross.svg'}></img>
				<div className='flex_center mb-4 mt-3'>
					<div style={{borderRadius: '6px', background: COLORS.gray3, fontSize: '24px', color: COLORS.gray6, padding: '8px 36px'}}>
						Current Subscription
					</div>
				</div>
				<div className='flex_center mb-4'>
					<div style={{color: COLORS.gray7, fontSize: '16px', fontWeight: 600}}>
						Contact your customer success executive for Add-on updation.
					</div>
				</div>
				<div className='flex_center' style={{gap: '48px'}}>
					<div style={{width: '300px'}}>
						{/* Base Plan */}
						<AddonCard addon={infurniaBasePlan} is_subscribed={infurniaBasePlan.subscribed} pricingDetails={pricingDetails}/>
					</div>
					{/* Addons Grid below */}
					<div className='p-4' style={{flex: 1, display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(240px,1fr))', gap: '36px', background: 'white', borderRadius: '10px', border: '1px solid var(--light-border-color)'}}>
						{
							Object.keys(infurniaAddons).map(addon => (
								<AddonCard addon={infurniaAddons[addon]} is_subscribed={infurniaAddons[addon].subscribed} pricingDetails={pricingDetails}/>
							))
						}
					</div>
				</div>
			</IModalBody>
		</Modal>
	)
}

export const New_Project_Modal = ({ open, handle_close }) => {

    const [project_name, set_project_name] = useState('')
    const [client_name, set_client_name] = useState('') 
    const [client_details, set_client_details] = useState('');
    const [order_id, set_order_id] = useState('') 
    const [new_project_id, set_new_project_id] = useState('') 
    const history = useHistory();
    const alert = useAlert()
    
    const get_default_project_name = async () => {
		try{
		var response = await window.Promisify(window.Module.get_number_of_projects());
		var default_project_name = "Project"+(Number(response)+1);
		set_project_name(default_project_name);
		}catch(err){
			if(window['sentry_capture_exception']){
				window['sentry_capture_exception']("",err);
			}
		}
    }

    useEffect(() => {
        if (open) {
            get_default_project_name();
        }
    }, [open])

    const onclick_close = () => {
        handle_close();
        set_project_name('');
        set_order_id('');
        set_client_name('');
    }

    const is_client_name_enforced = () => {
        if (window.Module && window.Module['is_client_name_enforced']) {
            return window.Module.is_client_name_enforced()
        } else {
            return false;
        }
    }

    const is_project_id_enforced = () => {
        if (window.Module && window.Module['is_project_id_enforced']) {
            return window.Module.is_project_id_enforced()
        } else {
            return false;
        }
    }

    const validate_project_name = (project_name) => {
        var re = /^[a-zA-Z0-9 ]+$/
        return re.test(project_name)
    }

    const onclick_submit = async (project_name, client_name = '', order_id = '', client_details) => {
        try{
            if(project_name.trim() && validate_project_name(project_name)){
                var response = await window.Promisify(window.Module.addProject(project_name, client_name, order_id, client_details));
                var obj_response=response && JSON.parse(response)
                alert.success('Project added successfully')
				// Sending Analytics on successfull Project Creation
				send_analytics({category: "New Project Created"})
                return (obj_response && obj_response.data && obj_response.data.project_id ? obj_response.data.project_id:'' );
            }
            else{
                if(!project_name){
                    alert.error('Project name is required')
                }else{
                    alert.error('Special Characters Not permitted. Please modify the Project Name')
                }
            }
            
        }
        catch(ee){
            //console.log(ee)
            alert.error('Request failed')    
        }     
    }

    const onclick_submit_half = async () => {
        if (!client_name && is_client_name_enforced()) {
            alert.error("Client name is required")
        } else if (!order_id && is_project_id_enforced()) {
            alert.error("Project ID is required")
        } else {
            var resp = await onclick_submit(project_name, client_name, order_id, client_details)
            if (project_name && resp) {
                set_project_name('')
                set_client_name('')
                handle_close()
                //console.log(resp);
                // var url = '/project/' + resp;
                // if(org_key && window.location.origin.includes("infurnia.com")){
                //     (url = "/" + org_key + url)
                // }
                // history.push(url)
				changeProject(resp, history)
            }
        }
    }

    return (
        <Modal className='modal-dialog-centered' isOpen={open} toggle={onclick_close} size='md'>
            <IModalHeader toggle={onclick_close}>New Project</IModalHeader>
            <IModalBody>
                <div className='new_project_modal_div'>
                    <span style={{ width: '30%', display: 'flex', alignItems: 'center' }}>Project Name*</span>
                    <Input className='common_input_styling' style={{ width: '70%' }} type='text' placeholder='Project Name' value={project_name} onChange={(e) => set_project_name(e.target.value)}></Input>
                </div>

                <div className='new_project_modal_div'>
                    <span style={{ width: '30%', display: 'flex', alignItems: 'center' }}>Client Name{is_client_name_enforced() ? '*' : ''}</span>
                    <Input className='common_input_styling' style={{ width: '70%' }} type='text' placeholder='Client Name' onChange={(e) => set_client_name(e.target.value)}></Input>
                </div>

                <div className='new_project_modal_div'>
                    <span style={{width:'30%' , display:'flex', alignItems:'center'}}>Client Details</span>
                    <Input className='common_input_styling' style={{width:'70%'}} type='text' placeholder='Client Details' value={client_details} onChange={(e) => set_client_details(e.target.value) } />
                </div>

                <div className='new_project_modal_div'>
                    <span style={{width:'30%' , display:'flex', alignItems:'center'}}>Project ID{is_project_id_enforced()?'*':''}</span>
                    <Input className='common_input_styling' style={{width:'70%'}} type='text' placeholder='Project ID' onChange={(e) => set_order_id(e.target.value)}></Input>
                </div>
            </IModalBody>
            <IModalFooter>
                <Button className='primary_button_default' onClick={onclick_submit_half}>Create Project</Button>
            </IModalFooter>
        </Modal>
    )
}

export const Lock_Warning_Modal = ({open, handle_close, active_item, onSubmit}) => {

	const alert = useAlert()

	const lock_design = async (item) => {
		try {
			// console.log(item)
			var resp = await window.Promisify(window.Module.lock_design(item.id, "false"));
			alert.success("Design locked successfully");
			// fetch_project_all_designs();
			if(onSubmit){
				onSubmit()
			}
			onclick_close_lock_warning_modal()
		} catch (err) {
			alert.error("Unable to lock design, Please try again later");
			console.error(err);
		}
	}
	
	const onclick_close_lock_warning_modal = () => {
		handle_close()
	}


	return (
		<Modal className='modal-dialog-centered' isOpen={open} toggle={onclick_close_lock_warning_modal} size='md'>
			{/* <ModalHeader style={{ backgroundColor:'rgba(34, 61, 95, 1)', color:'white'}} toggle={onclick_close}>New Project</ModalHeader> */}
			<IModalBody style={{ fontSize: '14px', backgroundColor: 'white', padding: '36px 16px', borderRadius: '4px' }}>
				<div style={{ textAlign: 'center', marginBottom: '24px' }}>
					Once the design is locked, you will not be able to edit it until an admin unlocks it. Are you sure you want to lock the design?
				</div>
				<div className='flex_center'>
					<Button onClick={() => lock_design(active_item)} className='primary_button_default mr-2'>Submit</Button>
					<Button onClick={onclick_close_lock_warning_modal} className='secondary_button_default'>Cancel</Button>
				</div>
			</IModalBody>
		</Modal>
	)
}

export const Upload3dModelModal = ({ open, handleclose, set_page_loader, callbackFnOn3DModelUpload}) => {

	const alert = useAlert();

	const [primary_file, set_primary_file] = useState('');
	const [mtl_file, set_mtl_file] = useState("");
	const [additional_files, set_additional_files] = useState([]);
	const [primary_file_type, set_primary_file_type] = useState('');
	const [mtl_text, set_mtl_text] = useState(null)
	const [expected_texture_files, set_expected_texture_files] = useState(null)

	const onclick_close_upload_3d_modal = () => {
		handleclose()
		set_primary_file('')
		set_mtl_file('')
		set_additional_files([])
		set_primary_file_type('')
	}

	const submit = async() => {
		try{
			await upload_asset({alert, set_page_loader, primary_file, primary_file_type, mtl_file, additional_files, expected_texture_files, callbackFnOn3DModelUpload}); 
			onclick_close_upload_3d_modal()
		}catch(err){
			console.error("error in submit", err)
		}
	}
	

	return (
		<Modal centered size="md" isOpen={open} toggle={onclick_close_upload_3d_modal}>
			<IModalHeader toggle={onclick_close_upload_3d_modal}>Add 3D Model</IModalHeader>
			<IModalBody style={{ maxHeight: '600px', overflow: 'auto', padding: '24px' }}>
				<Upload3dModel primary_file={primary_file} set_primary_file={set_primary_file} mtl_file={mtl_file} set_mtl_file={set_mtl_file} additional_files={additional_files} set_additional_files={set_additional_files} primary_file_type={primary_file_type} set_primary_file_type={set_primary_file_type} mtl_text={mtl_text} set_mtl_text={set_mtl_text} expected_texture_files={expected_texture_files} set_expected_texture_files={set_expected_texture_files}/>
			</IModalBody>
			<IModalFooter>
				{/* <Button
					disabled={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) ? false : true}
					onClick={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) ? upload_asset : null}
					className='primary_button_default' type="button" >
					Confirm Uploads
				</Button> */}
				<Button
					disabled={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) ? false : true}
					onClick={((primary_file && primary_file_type != 'obj') || (primary_file && mtl_file && primary_file_type === 'obj')) ? submit : null}
					className='primary_button_default' type="button" >
					Confirm Uploads
				</Button>
			</IModalFooter>
		</Modal>
	);
}
