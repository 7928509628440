import { evaluate } from 'mathjs';

export const validate_expression_interface = (expression, scope) => {
    if(window.portal === 'design'){
        return window.Module.validate_expression_interface(String(expression), JSON.stringify(scope));
    }else if(window.portal === "admin"){
        let val;
        try{
            val = evaluate(expression, scope);
        }catch(err){
            return "failure"
        }
        if(isNaN(val)) return "failure"
        return "success";
    }
}

export const parse_expression_interface = (expression, scope) => {
    if(window.portal === 'design'){
        return window.Module.parse_expression_interface(String(expression), JSON.stringify(scope));
    }else if(window.portal === "admin"){
        let val;
        try{
            val = evaluate(expression, scope);
        }catch(err){
            return false
        }
        if(isNaN(val)) return false
        return val;
    }
}

export const autofill_expression_interface = (expression, scope) => {
    if(window.portal === 'design'){
        return window.Module.autofill_expression_interface(String(expression), JSON.stringify(scope));
    }else if(window.portal === "admin"){
        let autofill = [];
        for(let i in scope){
            let param = i;
            if(param.substr(0, expression.length) == expression){
                let current_autofill = param.substr(expression.length, param.length - expression.length);
                autofill.push(current_autofill);
            }
        }
        return JSON.stringify(autofill);
    }
}