import React from 'react';
import './style.css';
import Icons from '../Icons';

export const IModalHeader = ({ children, toggle, custom_toggle_icon, style, className, style_children }) => {
  return (
    <div className={(className ? className : '') + " infurnia_modal_header"} style={{...style}}>
      <div style={{...style_children}}>{children}</div>
      <Icons onClick={toggle} style={{cursor:'pointer'}} name={custom_toggle_icon ? custom_toggle_icon : 'cross'}></Icons>
    </div>
  );
};

export const IModalBody = ({ children, style, className }) => {
  return (
    <div className={(className ? className : '') + " infurnia_modal_body"} style={{...style}}>
      {children}
    </div>
  );
};

export const IModalFooter = ({ children, style, className }) => {
  return (
    <div className={(className ? className : '') + " infurnia_modal_footer"} style={{...style}}>
      {children}
    </div>
  );
};
