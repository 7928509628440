import React, { useState, useEffect } from 'react';
import get_image_url_from_FS, {get_image_src} from '../../utils/get_image_url_from_FS';

const IImg = ({ src, fallbackSrc = '/resources/images/fallback_image_thumbnail.svg', alt, ...props }) => {
  const [error, setError] = useState(false);
  const [ img_src, set_img_src ] = useState(src);

  const handleImageError = () => {
    setError(true);
  };

  useEffect(() => {
    let url;
    if(src){
      if(src.includes('FS')){
        url = get_image_url_from_FS(src);
        set_img_src(url)
      }else{
        set_img_src(get_image_src(src))
      }
    }else{
      set_img_src('')
    }

    return () => {
      if(src && src.includes('FS')){
        if(url){
				  URL.revokeObjectURL(url)
        }
      }
      setError(false)
    }
  }, [src]);

  return (
    <img
      src={error ? fallbackSrc : img_src}
      onError={handleImageError}
      alt={alt}
      {...props}
    />
  );
};

export default IImg;
