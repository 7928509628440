/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import './style.css'
import changeProject from '../../utils/changeProject';
import { ExistingWorkOrders } from '../Modals';
import { handleError } from '../../utils/errors';

const Design_Dropdown = ({ active_item, project_details, onclick_open_modal, set_page_loader, submit_for_delete_confirm, store_details, fetchUpdatedData, style, page='home', ...props }) => {
    const [dropdown_open, set_dropdown_open] = useState(false);
    const [store_type, set_store_type] = useState("");
    const [admin_account, set_admin_account] = useState(false);
    const [user_id, set_user_id] = useState('');
	const [show_work_order_modal, set_show_work_order_modal] = useState();
	const [work_order_modal_json, set_work_order_modal_json] = useState();
	const [ has_mes_access, set_has_mes_access ] = useState(false);

    const alert = useAlert()
    const history = useHistory()

    const fetchWorkOrders = () => { 
        set_work_order_modal_json(JSON.parse(window.Module.get_work_order_modal_json(active_item.current_design_branch_id)));
    }

    const design_has_work_orders = () => {
        try{
            let work_orders = work_order_modal_json.work_orders
            return Array.isArray(work_orders) && work_orders.length
        }catch(err){
            handleError(err)
        }
        return false
    }

    const isDeleteDesignDisabled = () => {
        if(user_id != active_item.user_id){
            return "Only the original author can delete the design";
        }

        if(design_has_work_orders()){
            return "Cannot Delete Design with Work Orders";
        }
    }

    const request_unlock_design = async (item) => {
        try {
            var resp = await window.Promisify(window.Module.request_unlock_design(item.id));
            alert.success("Design unlock request sent successfully");
        } catch (err) {
            alert.error("Unable to request for unlock, Please try again later");
            console.error(err);
        }
    }

    const mark_branch_as_template = async (item) => {
        try {
            var resp = await window.Promisify(window.Module.mark_branch_as_template(item.current_design_branch_id));
            console.log(resp)
            if (resp == "UNAUTHORIZED_ACCESS") {
                alert.error("Insufficient permissions to mark as a template. Project manager role is required")
            } else {
                alert.success("Design is now a template design");
                fetchUpdatedData();
            }
        } catch (err) {
            alert.error("Unable to mark design as a template. Please try again later");
            console.error(err);
        }
    }

    const unmark_branch_as_template = async (item) => {
        try {
            var resp = await window.Promisify(window.Module.unmark_branch_as_template(item.current_design_branch_id));
            console.log(resp)
            if (resp == "UNAUTHORIZED_ACCESS") {
                alert.error("Insufficient permissions to unmark as a template. Project manager role is required")
            } else {
                alert.success("Design is no longer a template design");
                fetchUpdatedData();
            }
        } catch (err) {
            alert.error("Unable to mark design as not a template. Please try again later");
            console.error(err);
        }
    }

    const delete_design = (design_id) => {
        set_page_loader({
            show: true,
            text: 'Deleting design...'
        });

        setTimeout(async () => {
            try {
                var response = await window.Promisify(window.Module.delete_design(design_id))
                response = JSON.parse(response)
                if (response && response.action == 'TOAST_ERROR') {
                    alert.error(response.message)
                } else {
                    alert.success('Design Deleted Successfully')
                }
                fetchUpdatedData();

                // initiate_component();
            } catch (error) {
                alert.error("Unable to Delete Design. Please try later.")
                console.error("Unable to Delete Design. Please try later.", error)
            }
            set_page_loader({
                show: false,
                text: 'Please wait'
            });
        }, 0)
    }

    useEffect(() => {
        if (dropdown_open) {
            let active_user = window.Module.get_active_user()
            set_store_type(active_user.store_type)
            set_admin_account(active_user.admin_account)
            set_user_id(active_user.designer_id)

			let roles = JSON.parse(active_user.get_roles())
			set_has_mes_access(roles && (roles.includes('mes_user') || roles.includes('mes_editor')))
            fetchWorkOrders()
        }
    }, [dropdown_open])

    return (
        <Dropdown isOpen={dropdown_open} toggle={() => set_dropdown_open(!dropdown_open)}>
            <DropdownToggle /*className="btn-circle mr-0"*/ color='grey' style={{ backgroundColor: 'white', display: 'flex', padding: '8px', boxShadow: 'none', border: dropdown_open ? '1px solid #0078ff' : '1px solid var(--light-border-color)', ...style }} {...props}><span className="fa fa-bars" style={{ fontSize: '12px' }} /></DropdownToggle>
            <DropdownMenu container="body" className='DMSans design_dropdown_menu'>
                <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => user_id == active_item.user_id ? onclick_open_modal(active_item, 'edit_design') : ''}>Edit Name</DropdownItem>
                {page === 'home' ? <DropdownItem onClick={() => changeProject(active_item.Project.id, history)}>Open Project</DropdownItem> : ''}
				{has_mes_access && store_details && store_details.active_add_ons && store_details.active_add_ons.length && store_details.active_add_ons.find(o => ['1', '4', '6', '7'].includes(o.id)) ?
					<DropdownItem onClick={() => {fetchWorkOrders(); set_show_work_order_modal('existing_work_orders')}}>Create Work Order</DropdownItem> :''	
				}
                <DropdownItem onClick={() => onclick_open_modal(active_item, 'copy_design')}>Copy</DropdownItem>
                <DropdownItem onClick={() => onclick_open_modal(active_item, 'share_design')}>Share</DropdownItem>
                {
                    store_type != "professional" ?
                        active_item && !active_item.locked ?
                            <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => { user_id == active_item.user_id ? onclick_open_modal(active_item, 'lock_design') : '' }} >{'Lock'}</DropdownItem>
                            :
                            <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => { user_id == active_item.user_id ? request_unlock_design(active_item) : '' }} >{'Request Unlock'}</DropdownItem>

                        : ''
                }
                {(store_type != "professional" && active_item.current_branch_details && active_item.current_branch_details.org_template == 0) ? (<DropdownItem className={(!admin_account) ? 'disabled_dropdown_item' : ''} title={(!admin_account) ? "Only the right admin can mark the design as a template" : ""} onClick={() => (admin_account) ? mark_branch_as_template(active_item) : ''} >Mark as template</DropdownItem>) : ''}
                {(store_type != "professional" && active_item.current_branch_details && active_item.current_branch_details.org_template == 1) ? (<DropdownItem className={(!admin_account) ? 'disabled_dropdown_item' : ''} title={(!admin_account) ? "Only the right admin can mark the design as a template" : ""} onClick={() => (admin_account) ? unmark_branch_as_template(active_item) : ''} >Unmark as template</DropdownItem>) : ''}
                <DropdownItem className={user_id != active_item.user_id ? 'disabled_dropdown_item' : ''} title={user_id != active_item.user_id ? "Only the original author can modify the design" : ""} onClick={() => user_id == active_item.user_id ? onclick_open_modal(active_item, 'addn_prop') : ''} >Additional Properties</DropdownItem>
                {(active_item && !active_item.locked) ? (
                    // ''
                    <DropdownItem className={isDeleteDesignDisabled() ? 'disabled_dropdown_item' : ''} title={isDeleteDesignDisabled() ? isDeleteDesignDisabled()  : ""} onClick={!isDeleteDesignDisabled() ? () => (submit_for_delete_confirm(() => delete_design(active_item.id), () => { }, `If you confirm, The design ${active_item && active_item.name} will be sent to the Deleted Items section. If required, you can recover this design by going to that section`, {name: "Confirm"})) : () => { alert.error(isDeleteDesignDisabled()) }} ><span style={{ color: 'red' }}>Delete</span></DropdownItem>
                ) : ''}
            </DropdownMenu>
			<ExistingWorkOrders modal_type={show_work_order_modal} modal_json = {work_order_modal_json} handle_close = {() => set_show_work_order_modal(false)} set_page_loader={set_page_loader} project_client_name={project_details && project_details.client_name} project_name={project_details && project_details.project_name} />
        </Dropdown>
    )
}

export default Design_Dropdown;