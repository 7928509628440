import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './config';
import './index.css';
import './shared_components/styles/utility.css';
import './shared_components/styles/design_system.css';
import './override_defaults.css';
import App from './App';
import { BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
// import AlertTemplate from 'react-alert-template-basic'
import AlertTemplate from './components/AlertBox/';
import { ConfigProvider } from 'antd';
import "./i18n.js"

const options = {
  offset: '30px',
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 100001,
  },
}

ReactDOM.render(
<React.StrictMode>
  <Suspense fallback="loading">
    <ConfigProvider
      theme={{
        token: {
          colorBgSpotlight: '#323338'
        }
      }}
    >
      <AlertProvider template={AlertTemplate} {...options}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AlertProvider>
    </ConfigProvider>
  </Suspense>
</React.StrictMode>,
document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();



    

// module.exports = function (app) {
//   app.use(function (req, res, next) {
//       res.setHeader("Cross-Origin-Opener-Policy", "same-origin");
//       res.setHeader("Cross-Origin-Embedder-Policy", "require-corp");
//       next();
//   });
// };