import React, { useEffect, useState } from "react";

const ITriggerModal = ({ children, ModalComponent, modalProps, destroyOnClose, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen((prev) => !prev)

    return (
        <React.Fragment>
            { isOpen ? <ModalComponent open={isOpen} handle_close={toggleModal} {...modalProps}/> : '' }
            <div onClick={toggleModal} {...props}>
                {children}
            </div>
        </React.Fragment>
    )
}

export default ITriggerModal;