import debug_log from "./debug_log";
import { handleError } from "./errors";

export const send_analytics = (analytics_json = {}, require_branch_id = false) => {
    try {
        let active_user_details = window.Module.get_active_user()
        let analytics_json_complete = { ...analytics_json, "company_id": active_user_details.store_id, "user_id": active_user_details.designer_id }
        if (require_branch_id) {
            var split = window.location.pathname.split('/');
            var branch_id = split[split.length - 1];
            analytics_json_complete.design_branch_id = branch_id
        }
        analytics_json_complete.interface = window.Module.get_interface_mode();
        analytics_json_complete.perspective = window.Module.get_perspective();
        analytics_json_complete.view_name = window.Module.get_active_view_name();
        // will change it to component_editor later or give a function from core to identify directly.
        var is_seller_specific = window.location.href.includes('/seller/');
        analytics_json_complete.portal = "design"
        if(is_seller_specific){
            analytics_json_complete.portal = "seller"
            analytics_json_complete.editing_user_component = false
            if(window.Module.is_seller_component_a_user_element()){
                analytics_json_complete.editing_user_component = true
            }
        }
        try {
            if (window.Module.API.application_env == "production"){
                if(window.analytics){
                    window.analytics.track('auto', analytics_json_complete);
                    debug_log("analytics - ", analytics_json_complete)
                }
            }else{
                debug_log("analytics - ", analytics_json_complete)
                window.Module.Testing_API.add_analytics_data(JSON.stringify(analytics_json_complete))
            }
        } catch (err) {
            console.error("analytics failed with error ", err);
        }
    } catch (err) {
        console.error('Error in creating analytics data ', err)
    }
}

export const send_analytics_panel = ( json_item, parent ) => {
    try{
        // const top_level_component = window.get_panel_top_level_element()
        // if(top_level_component){
        //     let show_json_item = true;
        //     if(json_item.type === 'list_item' && json_item.is_material){
        //         show_json_item = false
        //     }
        //     let analytics_data = {}
        //     analytics_data.category = `${top_level_component} edited`;
        //     analytics_data.option = `${window.get_current_panel_level()}${(parent ? ('.' + parent.name) : '')}${json_item.name && show_json_item ? ('.' + json_item.name) : ''}`
        //     send_analytics(analytics_data, true)
        // }
    }catch(err){
        console.error("Could not send analytics_data for left panel property edited")
        handleError(err)
    }
}