import React, { useState,useEffect } from 'react';
import { Modal, Button, Form, Input, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import Multiselect from 'multiselect-react-dropdown';
import { COLORS } from '../../utils/color';
import { IModalBody, IModalHeader, IModalFooter } from '../../shared_components/ui_component_library/IModal';
import ISearch from '../ISearch';
import { UncontrolledCollapse } from '../ICollpsable';

const Filter_Info_Modal = ({open, handle_close, carousel_subcategories}) => {
	const [dummy_modal_json, set_dummy_modal_json] = useState([]);
	const [display_dummy_modal_json, set_display_dummy_modal_json] = useState(false);
	const [reason_map, set_reason_map] = useState({});

    useEffect(() => {
        set_dummy_modal_json(carousel_subcategories.skus_filtered)
    }, [carousel_subcategories])

	const transformName = (name) => {
		let temp = name.replace(/_/g, " ")
		return temp.charAt(0).toUpperCase() + temp.slice(1)
	}

	const handle_on_close = () => {
        handle_close()
	}

	const customComparator = (a, b) => {
		if (a.sub_category_name === b.sub_category_name) {
		  if (a.group_name === b.group_name) {
			if (a.sku_name === b.sku_name) {
			  return a.description.localeCompare(b.description);
			}
			return a.sku_name.localeCompare(b.sku_name);
		  }
		  return a.group_name.localeCompare(b.group_name);
		}
		return a.sub_category_name.localeCompare(b.sub_category_name);
	  };

	useEffect(() => {
		let temp = {};
		// Needed to maintain all the reasons before
		Object.keys(reason_map).map(reason => {
			temp[reason] = []
		})
		for(let idx in display_dummy_modal_json){
			if(temp[display_dummy_modal_json[idx]["reason"]]){
				if(Array.isArray(temp[display_dummy_modal_json[idx]["reason"]])) {
					temp[display_dummy_modal_json[idx]["reason"]].push(display_dummy_modal_json[idx]);
				}
			}
			else {
				temp[display_dummy_modal_json[idx]["reason"]] = [display_dummy_modal_json[idx]];
			}
		}
		for(let reason in temp){
			temp[reason].sort(customComparator)
		}
		set_reason_map(temp);
	}, [display_dummy_modal_json])

	return (
		<Modal size='xl' isOpen={open}>
		<IModalHeader toggle={handle_on_close}>Filtered SKUs</IModalHeader>
		<IModalBody style={{height: '600px', overflow: 'auto'}}>
		<ISearch list={dummy_modal_json} set_list={set_display_dummy_modal_json} search_parameters={['sku_name', 'sub_category_name', 'group_name','description']} placeholder={'Search by Sub-category name, Group name or SKU name'} style_outer={{ margin: '0px 0px 16px 0px' }} />
			{Object.keys(reason_map).map(reason => (
				<div style={{padding: '10px 0px'}}>
				<UncontrolledCollapse header={transformName(reason)} style_header={{backgroundColor: COLORS.gray3, fontSize: '14px', borderBottom: '1px solid lightgrey'}}>
					<table style={{width:'100%', marginBottom: '15px'}} className="text-left flex_property table_style">
						<thead style={{width:"100%"}}>
							<tr className='tr_style' style={{borderTop:'1px solid #f2f4f6', height:"40px"}}>
								<th className='th_style flex_property' style={{flex:'1'}}><span>Sub-category Name</span></th>
								<th className='th_style flex_property' style={{flex:'1'}}><span>Group Name</span></th>
								<th className='th_style flex_property' style={{flex:'1'}}><span>SKU Name</span></th>
								<th className='th_style flex_property' style={{flex:'3'}}><span>Description</span></th>
							</tr>
						</thead>
						<tbody style={{ height:"calc(100% - 40px)", width:'100%',overflow:'auto'}}>
							{reason_map[reason].map(item => (
							<tr style={{display:'flex', margin:0}} className='tr_style'>
								<td className='td_style_no_scroll flex_property' style={{ flex:'1'}}>
								<span className='table_text_overflow'>{item.sub_category_name}</span>
								</td>
								<td className='td_style_no_scroll flex_property' style={{ flex:'1'}}>
								<span className='table_text_overflow'>{item.group_name}</span>
								</td>
								<td className='td_style_no_scroll flex_property' style={{ flex:'1'}}><span className='table_text_overflow'>{item.sku_name}</span></td>
								<td className='td_style_no_scroll flex_property' style={{ flex:'3'}}>
								<span className='table_text_overflow'>{item.description}</span>
								</td>
							</tr>
							))}
						</tbody>
					</table>
				</UncontrolledCollapse>
				</div>
			))}
		</IModalBody>
		<IModalFooter>
			<Button onClick={handle_on_close} className='primary_button_default'>Close</Button>
		</IModalFooter>
		</Modal>
	)
	
}
export default Filter_Info_Modal;