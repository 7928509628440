import React from "react";
import Icons from "../Icons";
import { Dropdown } from "antd";

export const ButtonsDropdown = ({ button_list, icon_on_click, customToggle, customStyle = { menu: {} } }) => {
    return (
        button_list && button_list.length ?
            <Dropdown trigger={['click']} menu={{
                items:
                    button_list.map((item, idx) => {
                        return {
                            label:
                                item.type == "divider" ?
                                    ''
                                    :
                                    <div style={{ color: item.color ? item.color : '' }} disabled={item.disabled} onClick={(e) => { if (item.customOnClick) { item.customOnClick({ id: item.name, additional_data: item.additional_data }) } else if (icon_on_click) { icon_on_click({ route: item.onclick, form: { id: item.name, additional_data: item.additional_data ? item.additional_data : '' } }); } }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                            {item.icon ? <img src={item.icon} style={{ height: '16px', width: '16px' }} /> : ''}
                                            <div style={{ color: item.color ? item.color : '' }}>{item.display_name}</div>
                                        </div>
                                    </div>,
                            key: idx,
                            type: item.type
                        }
                    })

            }}>
                {
                customToggle ? customToggle : 
                    <Icons name={'vertical_options'} style={{fontSize: '12px', padding: '4px'}} />
                }
            </Dropdown>
            : ''

    )
}