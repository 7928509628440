import React, { useEffect, useMemo, useState } from 'react';
import {Dropdown,  DropdownItem, DropdownToggle, DropdownMenu, Row, Col} from "reactstrap";
import { Dropdown as AntdDropdown } from 'antd';
import { COLORS } from '../../utils/color';

import './style.css';
import { generate_2d_snapshot_of_composite, generate_3d_snapshot_of_composite } from '../../utils/hatching';
import repopulate_panel_pop_up from '../../utils/panel_pop_up_repopulation';
import repopulate_panel from '../../utils/panel_repopulation';
import IImg from '../IImg';

const CompositeDropdown = ({ composites, active_index, on_click, json_item }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [composites_copy, set_composites_copy] = useState('');
    // const [rename_enabled, set_rename_enabled] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const fetch_composite_images = async(copy_composites, set_composites_copy) => {
        if(copy_composites && copy_composites.length){
            for(let i = 0; i < copy_composites.length; i++){
                copy_composites[i].cut_snapshot = generate_2d_snapshot_of_composite(copy_composites[i])
                copy_composites[i].surface_snapshot = await generate_3d_snapshot_of_composite(copy_composites[i])
                // console.log('composites copy surface', composite.surface_snapshot)
                
            }
        }
        // console.log('composites copy surface', copy_composites)
        set_composites_copy(JSON.parse(JSON.stringify(copy_composites)))
    }

    useEffect(() => {
        // set_composites_copy(composites)
        fetch_composite_images(composites, set_composites_copy)
    }, [composites]);

    // useEffect(() => {
    //     // console.log('composites copy', composites_copy)
    // }, [composites_copy]);

    return (
        <Row className='class_position_unset' style={{margin:0, height:'100%'}}>
            <Col className='class_position_unset' style={{padding:0, display:'flex', alignItems:'center'}}>
                <Dropdown className='class_position_unset' isOpen={dropdownOpen} style={{all:'unset', listStyle: 'none'}} direction="up" toggle={json_item && json_item.disabled?null:toggle}>
                    <DropdownToggle  style={{display:'flex',flexFlow:'row', backgroundColor:'white',cursor:'pointer', color:'black', fontSize:'13px', padding:'4px 10px', border:'1px solid  '+ COLORS.panel_item_borders, alignItems:'center'}}>
                        <div style={{display:'flex',flexFlow:'row',fontSize:'13px',width:'150px', alignItems:'center'}}>
                            {composites_copy && active_index != undefined && composites_copy[active_index] && composites_copy[active_index].cut_snapshot ? (<img src={composites_copy[active_index].cut_snapshot} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                            {composites_copy && active_index != undefined && composites_copy[active_index] && composites_copy[active_index].surface_snapshot ?(<img src={composites_copy[active_index].surface_snapshot} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                            <div title={composites && composites[active_index]?composites[active_index].name:''} style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{composites && composites[active_index]?composites[active_index].name:'Select Composite'}</div>
                        </div>
                        <span><i className='fa fa-caret-down' /></span>
                    </DropdownToggle>
                    <DropdownMenu className="composite_dropdown_menu" style={{padding:'4px'}}>
                        {composites_copy && composites_copy.map((composite,idx) => (
                            <DropdownItem style={{listStyle: 'none'}} onClick={() => {on_click(composite.id)}} key={idx}>
                                <div style={{listStyle: 'none',display:'flex',flexFlow:'row',fontSize:'13px'}}>
                                    {composite.cut_snapshot?(<img src={composite.cut_snapshot} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                                    {composite.surface_snapshot?(<img src={composite.surface_snapshot} style={{width:'30px',height:'30px', backgroundColor:'beige', marginRight:'5px'}}/>):''}
                                    <div style={{paddingTop:'5px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{composite.name}</div>
                                </div>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </Row>
    );
}


export const CompositeDropdownPanel = ({ composites, json_item, update_view, is_panel_pop_up }) => {
    const [composites_copy, set_composites_copy] = useState('');

    const get_active_index = (composites, active_id) => {
		return window._.findIndex(composites,o => o.id == active_id);
	}

    const active_index = useMemo(() => get_active_index(composites, json_item.active_composite_id), [composites, json_item])

    const composite_select_onclick = ({key: composite_id}) => {
		if(json_item.route){
			if(window.debug_mode){
				window.add_debug_log(json_item.route + "(\"" + composite_id + "\");");
			}
			window.Module[json_item.route](composite_id);
			is_panel_pop_up ? repopulate_panel_pop_up() : repopulate_panel();
			update_view();
		}
	}

    const fetch_composite_images = async(copy_composites, set_composites_copy) => {
        if(copy_composites && copy_composites.length){
            for(let i = 0; i < copy_composites.length; i++){
                copy_composites[i].cut_snapshot = generate_2d_snapshot_of_composite(copy_composites[i])
                copy_composites[i].surface_snapshot = await generate_3d_snapshot_of_composite(copy_composites[i])
            }
        }
        set_composites_copy(JSON.parse(JSON.stringify(copy_composites)))
    }

    useEffect(() => {
        fetch_composite_images(composites, set_composites_copy)
    }, [composites]);

    return (
                <AntdDropdown trigger={['click']} placement='bottomLeft' menu={{
                    onClick: composite_select_onclick,
                    items:
                        composites_copy && composites_copy.map((composite,idx) => {
                            return {
                                key: composite.id,
                                label:  <div className='flex_property inf-gap-1'>
                                {composite.cut_snapshot?(<IImg className="inf-border" src={composite.cut_snapshot} style={{width:'30px',height:'30px'}}/>):''}
                                {composite.surface_snapshot?(<IImg className="inf-border" src={composite.surface_snapshot} style={{width:'30px',height:'30px'}}/>):''}
                                <div>{composite.name}</div>
                            </div>
                            }
                        })
                }}>
                    <div className='flex_property w-full inf-py-1 inf-px-2 cp inf-border rounded'>
                        <div className='flex-1-mw flex_property inf-gap-1'>
                            {composites_copy && active_index != undefined && composites_copy[active_index] && composites_copy[active_index].cut_snapshot ? (<IImg className='inf-border' src={composites_copy[active_index].cut_snapshot} style={{width:'24px',height:'24px'}}/>):''}
                            {composites_copy && active_index != undefined && composites_copy[active_index] && composites_copy[active_index].surface_snapshot ?(<IImg className='inf-border' src={composites_copy[active_index].surface_snapshot} style={{width:'24px',height:'24px'}}/>):''}
                            <div className='flex-1-mw lines1_elipsis' title={composites && composites[active_index]?composites[active_index].name:''} >{composites && composites[active_index]?composites[active_index].name:'Select Composite'}</div>
                        </div>
                        <div><i className='fa fa-caret-down' /></div>
                    </div>
                </AntdDropdown>
    );
}


export default CompositeDropdown;

