/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {Dropdown, Input, DropdownItem, DropdownToggle, DropdownMenu, Row, Col, UncontrolledDropdown} from "reactstrap";
import { useAlert } from 'react-alert'

import './style.css';
import { Tooltip } from 'antd';
import Icons from '../../shared_components/ui_component_library/Icons';

const styles_panel = {
    dropdown_item: {
        paddingLeft:'6px',
        paddingRight:'6px',
        paddingBottom: '0.5rem',
        paddingTop: '0.5rem',
    }
}

const DesignView_Dropdown = ({idx, view, number_of_views, update_view, perspective, all_design_views, set_all_design_views, submit_for_delete_confirm, handle_ui_response, switch_free_view, remote_view}) => {
    const alert = useAlert()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [rename_enabled, set_rename_enabled] = useState(false);
    const [view_options, set_view_options] = useState([]);
    const [view_settings_options, set_view_settings_options] = useState([])
    const [is_locked, set_is_locked] = useState(false);
    const [locked_route, set_locked_route] = useState('');
    const [show_confirm_delete_modal, set_show_confirm_delete_modal] = useState(false);
    const [json_item_modal, set_json_item_modal] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    // const [{ isDragging }, dragRef] = useDrag({
    //     type: 'item',
    //     item: { idx },
    //     collect: (monitor) => ({
    //         isDragging: monitor.isDragging(),
    //     }),
    // })

    // const [spec, dropRef] = useDrop({
    //     accept: 'item',
    //     hover: (item, monitor) => {
    //         const dragIndex = item.idx
    //         const hoverIndex = idx
    //         const hoverBoundingRect = ref && ref.current && ref.current.getBoundingClientRect()
    //         const hoverMiddleY = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
    //         const hoverActualY = monitor.getClientOffset().x - hoverBoundingRect.left

    //         // if dragging down, continue only when hover is smaller than middle Y
    //         if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
    //         // if dragging up, continue only when hover is bigger than middle Y
    //         if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

    //         dndViewItem(dragIndex, hoverIndex)
    //         item.index = hoverIndex
    //     },
    // })

    // // Join the 2 refs together into one (both draggable and can be dropped on)
    // const ref = useRef(null)
    // const dragDropRef = dragRef(dropRef(ref))

    const toolbar_on_click = async id => {
		var promise_resp;
		try{
            if(window.debug_mode){
                window.add_debug_log("toolbar_router(\"" + id.replace(/"/g, '\\"') + "\");");
            }
            promise_resp = window.Module.toolbar_router(id);
		}catch(err){
			err.constructor.name.includes("Error") != true?err=new Error(err):null;
			err.name = "Captured error in Toolbar onclick error at " + id + " : " + err.name;
			console.error(err);
			window.sentry_capture_exception("",err);
			alert.error("Something went wrong")
			return;
		}

		var ui_response = await window.Promisify(promise_resp);

		// handle_ui_response(ui_response);
		update_view();
	}

    const move_view = (direction) => {
        if(window.debug_mode){
            var curr_view_index = 0;
            all_design_views.map((o, idx) => { if(o.id == view.id){curr_view_index = idx;} });
            window.add_debug_log("move_design_view(active_design->design_views[" + curr_view_index + "]->id,\"" + direction + "\");");
        }
        window.Module.move_design_view(view.id, direction);
        // set_view_options(JSON.parse(window.Module.get_view_specific_options()));

        update_view();
    }

    const delete_view = () => {
        if(window.debug_mode){
            var curr_view_index = 0;
            all_design_views.map((o, idx) => { if(o.id == view.id){curr_view_index = idx;} });
            window.add_debug_log("delete_design_view(active_design->design_views[" + curr_view_index + "]->id);");
        }
        window.Module.delete_design_view(view.id);
        update_view();
    }

    const activate_rename_view = () => {
        set_rename_enabled(true);
        setTimeout(() => {
            let view_dom = document.getElementById('rename'+view.id)
            if(view_dom){
                view_dom.focus()
            }
        },10)
    }

    const rename_view = async(new_name) => {
        if(window.debug_mode){
            var curr_view_index = 0;
            all_design_views.map((o, idx) => { if(o.id == view.id){curr_view_index = idx;} });
            window.add_debug_log("rename_design_view(active_design->design_views[" + curr_view_index + "]->id,\"" + new_name + "\");");
        }
        var form = {id: 'rename_view'};
        form['value'] = new_name;
        form['view_id'] = view.id;
        var promise_resp;
        try{
            promise_resp = window.Module['rename_design_view'](JSON.stringify(form));
        }catch(err){
            err.constructor.name.includes("Error") != true?err=new Error(err):null;
            err.name = "Captured error in View specific settings at " + 'rename_design_view' + " : " + err.name
            console.error(err);
            window.sentry_capture_exception("",err);
            alert.error("Something went wrong")
            return;
        }
        var ui_response = await window.Promisify(promise_resp);
        
        handle_ui_response(ui_response);
        update_view()
        set_rename_enabled(false);
    }

    const switch_view = () => {
        if(!view.active_view){
            if(window.debug_mode){
                var curr_view_index = 0;
                all_design_views.map((o, idx) => { if(o.id == view.id){curr_view_index = idx;} });
                window.add_debug_log("switch_design_view(active_design->design_views[" + curr_view_index + "]->id);");
            }
            window.Module.switch_design_view(view.id);
            try { 
                let options = JSON.parse(window.Module.get_view_specific_options());
                console.log("view options 0", options);
                // set_view_options(JSON.parse(window.Module.get_view_specific_options()));
                set_view_options(options);
                set_view_settings_options(JSON.parse(window.Module.get_options_for_view()));
            } catch (err) {
                console.log(err)
            }
            
            update_view();
        }
    }

    const close_view = async() => {
        var promise_resp;
        try{
            window.Module.open_close_design_view(view.id, false);
        }catch(err){
            err.constructor.name.includes("Error") != true?err=new Error(err):null;
            err.name = "Captured error in View specific settings at open_close_design_view : " + err.name;
            console.error(err);
            window.sentry_capture_exception("",err);
            alert.error("Something went wrong")
            return;
        }
        if(view.active_view){
            switch_free_view()
        }
        else
            update_view()
    }

    const handleEnter = (new_name) => {
        if(new_name == ""){
            alert.error("View name cannot be empty")
            return;
        }
        rename_view(new_name)
        setTimeout(() => {
            let view_dom = document.getElementById('rename'+view.id)
            if(view_dom){
                view_dom.blur()
            }
        },10)
    }
    
    const handleOnBlur = (e) =>{
        if(e.target.value == ""){
            alert.error("View name cannot be empty")
            return;
        }
        rename_view(e.target.value)
        let view_dom = document.getElementById('rename'+view.id)
        if(view_dom){
            view_dom.blur()
        }
    }

    useEffect(() => {
        if(window.debub_mode){
            console.log("view options", view_options)
        }
    }, [view_options]);

    useEffect(() => {
        if(view.active_view){
            set_view_options(JSON.parse(window.Module.get_view_specific_options()));
            set_view_settings_options(JSON.parse(window.Module.get_options_for_view()));
            // update_view()
        }
    }, [view, dropdownOpen, perspective]);

    useEffect(() => {
        // console.log("KPP view options are", view_options)
        if(view_options && view_options.length){
            let idx = view_options.map(x => x.id).indexOf('lock_view')
            // console.log("idx", idx)
            if(idx >= 0){
                set_is_locked(view_options[idx].selected)
                if(view_options[idx].disabled){
                    set_locked_route('disabled')
                }
                else{
                    set_locked_route('lock_view')
                }
            }else{
                set_locked_route('')
            }
        }
        
    }, [view_options]);

    const handle_option_click = async(e, json_item, op_id, view_id, value='') => {
        var promise_resp;
        var form = {};
        form['id'] = op_id;
        form['view_id'] = view_id;
        form['value'] = value;
			try{
				let resp = await window.Promisify(window.Module[json_item.onchange_route](JSON.stringify(form)));
                handle_ui_response(resp)
			}catch(err){
				err.constructor.name.includes("Error") != true?err=new Error(err):null;
				err.name = "Captured error in View specific settings at " + json_item.onchange_route + " : " + err.name
				console.error(err);
				window.sentry_capture_exception("",err);
				alert.error("Something went wrong")
				return;
			}
        if(json_item.id == 'close_view'){
            switch_free_view();
        }
        else
		    update_view()
    }

    return (
        <Row id={'view_div'+idx} onClick={switch_view} style={{margin:0, height:'100%', cursor: view.active_view?'':'pointer', borderLeft: view.active_view ? '1px solid #8E8F9E' : '1px solid #C5C7CF', borderRight: view.active_view ? '1px solid #8E8F9E' : '1px solid #C5C7CF'}} className={view && view.active_view?'active_view_container':'inactive_view_container'}>
            <Col className={view && view.active_view?'active_view':'inactive_view'} style={{padding:0, height:'100%', display:'flex', alignItems:'center', maxWidth: '150px', position:"unset",/* borderTop:view.active_view?"4px solid #0078FF":'4px solid transparent' borderBottom: view.active_view ? '1px solid #0078FF' : ''*/}}>
                <div className={view.active_view?'active_view':'inactive_view'} style={{display: 'flex', alignItems: 'center', marginBottom: view.active_view ? '-1px' : ''}}>
                    {view.active_view && view.locked ? <div onClick={(e) => {if(view.locked) {handle_option_click(e, view_settings_options.find(item => item.id == 'lock_unlock_view'), view_settings_options.find(item => item.id == 'lock_unlock_view').id, view.id, !view.locked); set_view_options(JSON.parse(window.Module.get_view_specific_options()));update_view()}}} style={{padding: '4px 4px 4px 8px'}}>{view.locked? <Tooltip overlayStyle={{maxWidth: '350px'}} placement='top' title={<div style={{display: 'flex', flexDirection: 'column', gap: '5px', paddingLeft: '10px'}}><div style={{fontWeight: '400'}}>View is Locked.</div> <div style={{fontWeight: '50'}}>No levels changes or camera movements allowed. Click on the icon to unlock the view</div></div>} mouseEnterDelay={0.5}><div className='solid_lock_unlock'><Icons name='lock' className='solid_lock' style={{fontSize: '16px'}} /> <Icons name='unlock' className='solid_unlock' style={{fontSize: '16px'}} /> </div></Tooltip> : ''}</div> : ''}
                    <img src={view.type?'/resources/view_bar_icons/' + view.type + '_type.svg':'/resources/view_bar_icons/floorplan_type.svg'} style={{padding: view.active_view? (view.locked ? '4px 0px 4px 4px' : '4px 0px 4px 8px'):'4px 0px 4px 8px'}}></img>
                </div>
                <Tooltip placement='top' title={view.name} mouseEnterDelay={0.5}>
                    <div className='x1 flex_center' style={{ paddingLeft: '10px', fontSize: '12px', height: '100%', padding: '4px 10px 4px 4px', overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'pointer', marginBottom: view.active_view ? '-1px' : '' }}>
                        {rename_enabled ? (
                            <Input className="view_rename_box" style={{ height: '100%', width: '100px', cursor: 'pointer', fontSize: '12px' }} defaultValue={view.name} type="text" id={'rename' + view.id} onKeyDown={(e) => { (e.key === 'Enter' || e.key === 'Escape') && handleEnter(e.target.value); }} onBlur={(e) => { handleOnBlur(e) }} />
                        ) : (
                            <div style={{ cursor: 'pointer', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}} onDoubleClick={activate_rename_view}>{view.name}</div>
                        )}
                    </div>
                </Tooltip>
                {/* <ConfirmDeleteModal open={show_confirm_delete_modal} handleclose_alert={(e) => set_show_confirm_delete_modal(false)} delete_fn={(e) => {handle_option_click(e, json_item_modal, json_item_modal.id, view.id); set_show_confirm_delete_modal(false); set_json_item_modal(false);}} custom_mssg={<span>Do you want to <span style={{fontWeight: 'bold'}}>Delete the view permanently</span></span>}/> */}
                {
                    <>
                    {/* {view.active_view ?
                        <div className='active_view' style={{padding:'4px 8px 4px 0px'}}>
                            <div className='flex_property' style={{backgroundColor: '#F6F7F9', borderRadius:'4px'}}>
                                {
                                    view_options && view.active_view && view_options.length ? view_options.map((x,idx) => (
                                        x.src && !x.disabled ? <img height={'16px'} className={'mr-2' + (idx == 0 ? ' ml-2' : '')} key={idx} src={x.src} title={x.tooltip_icon ? x.tooltip_icon : ''}></img> : ''
                                    ))
                                    :''
                                }
                        
                                <Dropdown direction='up' nav isOpen={dropdownOpen} toggle={toggle} className='flex_center' style={{height:'100%', position:'unset'}}>
                                    <DropdownToggle nav className={view.active_view?'active_view':''} style={{fontSize:'9px', padding:'4px 10px 4px 0px', border:0, backgroundColor:'transparent'}}>
                                        <img src='/resources/icons/cog_blue_bg.svg' height='16px'></img>
                                    </DropdownToggle>
                                    <DropdownMenu className={(idx==0 || idx==number_of_views-1) ? 'free_view_dropdown3' : 'free_view_dropdown2'} style={{padding:'4px', marginBottom:'9px', marginLeft:'-55px'}}>
                                        {
                                            view_options && view_options.length ? view_options.map((x,idx) => (
                                                x.id === 'lock_view' ? '' : <DropdownItem toggle={false} disabled={x.disabled} title={x.disabled_message ? x.disabled_message : ''} key={idx} onClick={x.disabled ? null : (e) => {toolbar_on_click(x.id);set_view_options(JSON.parse(window.Module.get_view_specific_options()));update_view()}} style={{fontSize:'13px', pointerEvents:'auto', ...styles_panel.dropdown_item}}><span> <i className={x.selected ? 'fa fa-check-square' : 'fa fa-square-o'} style={{marginRight: '4px', color:x.selected ? '#0078ff' : '#C5C7CF'}}></i>  {x.name} </span></DropdownItem>
                                            ))
                                            :''
                                        }
                                        <DropdownItem divider />
                                        <DropdownItem onClick={activate_rename_view} style={{fontSize:'13px', ...styles_panel.dropdown_item}}>Rename</DropdownItem>
                                        {locked_route ?(<DropdownItem disabled={locked_route === 'disabled'} onClick={(e) => {toolbar_on_click(locked_route);set_view_options(JSON.parse(window.Module.get_view_specific_options()));update_view()}} style={{fontSize:'13px', ...styles_panel.dropdown_item}}><div className='flex_property'><img height='16px' style={{marginRight:'4px'}} src={is_locked ? '/resources/panel_icons_new/unlock layer.svg' : '/resources/panel_icons_new/lock layer.svg'} /> <div className='flex_property'>{is_locked ? "Unlock View" : "Lock View"}</div></div></DropdownItem>):''}
                                        {view.order != 0?(<DropdownItem onClick={() => {move_view('left')}} style={{fontSize:'13px', ...styles_panel.dropdown_item}}>Move Left</DropdownItem>):''}
                                        {view.order != (number_of_views - 1)?(<DropdownItem onClick={() => {move_view('right')}} style={{fontSize:'13px', ...styles_panel.dropdown_item}}>Move Right</DropdownItem>):''}
                                        <DropdownItem onClick={delete_view} style={{fontSize:'13px', ...styles_panel.dropdown_item, color:'#FF5247'}}>Delete View</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    :''} */}
                    <div className= {view.active_view?'active_view':''} style={{display: 'flex', alignItems: 'center', marginBottom: view.active_view ? '-1px' : ''}}>
                        <Dropdown direction='up' nav isOpen={dropdownOpen} toggle={toggle} className='flex_center' style={{height:'100%', position:'unset', display: (view.active_view && !remote_view) ? '' : 'none'}}>
                            <DropdownToggle nav className={view.active_view?'active_view':''} style={{fontSize:'9px', padding:'4px 8px 4px 0px', border:0, backgroundColor:'transparent'}}>
                                {/* <img src='/resources/view_bar_icons/view_settings.svg' height='16px'></img> */}
                                <Icons name='View_settings' className='view_settings_icon' style={{fontSize: '16px'}}/>
                            </DropdownToggle>
                            <DropdownMenu className={(idx==0 || idx==number_of_views-1) ? 'free_view_dropdown3' : 'free_view_dropdown2'} style={{padding:'4px', marginBottom:'9px', marginLeft:'-55px'}}>
                                {
                                view_settings_options.map(item => 
                                <DropdownItem onClick={item.id=='rename_view' ? (e) => {activate_rename_view()} : item.onchange_route ? item.id == 'delete_view' ? (e) => {submit_for_delete_confirm((e) => handle_option_click(e, item, item.id, view.id), () => {}, <span>Do you want to <span style={{fontWeight: 'bold'}}>delete the view permanently?</span> Images in PPT linked to this view will also be deleted.</span>);} : (e) => {handle_option_click(e, item, item.id, view.id, item.id=='lock_unlock_view' ? (!view.locked) : item.id=='close_view' ? !view.open : '')} : null} style={{fontSize:'13px', ...styles_panel.dropdown_item}}> <div className='flex_property'> <img height='16px' style={{marginRight:'4px'}} src={item.icon} /> <div className='flex_property' style={{paddingLeft: '4px'}}>{item.name}</div> </div> </DropdownItem>
                                )
                                }
                                {/* {locked_route ?(<DropdownItem disabled={locked_route === 'disabled'} onClick={(e) => {toolbar_on_click(locked_route);set_view_options(JSON.parse(window.Module.get_view_specific_options()));update_view()}} style={{fontSize:'13px', ...styles_panel.dropdown_item}}><div className='flex_property'><img height='16px' style={{marginRight:'4px'}} src={is_locked ? '/resources/panel_icons_new/unlock layer.svg' : '/resources/panel_icons_new/lock layer.svg'} /> <div className='flex_property'>{is_locked ? "Unlock View" : "Lock View"}</div></div></DropdownItem>):''} */}
                                {/* <DropdownItem onClick={delete_view} style={{fontSize:'13px', ...styles_panel.dropdown_item, color:'#F45229'}}><div className='flex_property'> <img height='16px' style={{marginRight:'4px'}} src={'/resources/view_bar_icons/delete_view.svg'} /> <div className='flex_property'>Delete view</div> </div></DropdownItem> */}
                            </DropdownMenu>
                        </Dropdown>
                        {
                        // view.active_view?
                        // <img className={(view && view.active_view) ? '' : 'close_icon_on_hover'} src='/resources/view_bar_icons/close_view_raw.svg' height='18px' style={{padding: view.active_view?'4px 8px 4px 0':'4px 8px 4px 4px', cursor: 'pointer'}} onClick={(e) => {e.stopPropagation(); close_view();}}></img>
                        <div className={(view && view.active_view) ? '' : 'close_icon_on_hover'} style={{padding: view.active_view?'4px 8px 4px 0':'4px 8px 4px 4px', cursor: 'pointer'}} onClick={(e) => {e.stopPropagation(); close_view();}}>
                            <Icons name='close_view' className='close_view' style={{fontSize: '11px'}}/>
                        </div>
                        // : ''
                        }
                    </div>
                    </>
                }                
            </Col>
        </Row>
    );
}


export default DesignView_Dropdown;

